<script setup>
/* global FB */
import MaterialSocialButton from "@/components/MaterialSocialButton.vue";
import { ref, nextTick, onMounted, watch } from "vue";
import FacebookAccountsTable from "./Components/FacebookAccountsTables.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import {
  createNewFacebookInfo,
  updateFacebookInfoDetails,
} from "@/services/facebookDataSource";
import { getLongLiveToken } from "@/utils/facebookSDK";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  fbInfo: {
    type: Object,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  refId: {
    type: Object,
    default: () => ({}),
  },
});
const userName = ref("");
const userAdAccounts = ref([]);
const userAdCampaigns = ref([]);
const userNameElementRef = ref("");
const showLogoutButton = ref(false);

const emit = defineEmits(["next-step", "prev-step", "update-data"]);
const notifyParentToUpdateData = () => {
  emit("update-data");
};
const submitFacebookLogin = (event) => {
  event.preventDefault();
  handleFacebookSync();
};
async function handleFacebookSync() {
  try {
    FB.login(
      (loginResponse) => {
        if (loginResponse.authResponse) {
          console.info("Welcome!  Fetching your information.... ");
          let accessToken = loginResponse.authResponse.accessToken;
          let userId = loginResponse.authResponse.userID;
          getLongLiveToken(accessToken)
            .then((longLivedAT) => {
              if (longLivedAT) {
                accessToken = longLivedAT;
              }

              FB.api("/me", async function (meResponse) {
                if (meResponse && meResponse.error) {
                  const text =
                    "Problema ao buscar suas informações de usuário. Por favor, fale com seu consultor: " +
                    meResponse.error.message;
                  store.dispatch("setAlertTextAndColor", {
                    text,
                    color: "danger",
                  });
                }
                console.log("Good to see you, " + meResponse.name + ".");
                userName.value = meResponse.name;
                nextTick(() => {
                  try {
                    const element = userNameElementRef.value;
                    element.focus();
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  } catch (error) {
                    console.error("Error during nextTick", error);
                  }
                });
                const facebookUserId = userId || meResponse.id;
                // fetch(
                //   "http://graph.facebook.com/" +
                //     facebookUserId +
                //     "/picture?access_token=" +
                //     accessToken +
                //     "&type=large&redirect=true&width=500&height=500"
                // )
                //   .then((response) => {
                //     console.log("response ", response);
                //   })
                //   .catch((error) => {
                //     console.error("Error during fetch", error);
                //   });
                // let imageUrl =
                //   "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=377606381274566&height=50&width=50&ext=1704748162&hash=AfrjnBRX9GEoTp1EGk3y71qXhP8nBzYc-vOMILTlNNC93w";

                // fetch(imageUrl)
                //   .then((res) => res.blob())
                //   .then((blob) => {
                //     const picUrl = URL.createObjectURL(blob);
                //     console.log("picUrl: ", picUrl);
                //     userProfilePictureURL.value = picUrl;
                //   });
                const data = {
                  accessToken,
                  fbUserId: facebookUserId,
                  fbUserName: userName.value,
                  projectId: props.projectId,
                  facebookStatus: true,
                };
                await createNewFacebookInfo(data);
                notifyParentToUpdateData();
              });
            })
            .catch((error) => {
              console.error("Error during getLongLiveToken", error);
            });
        } else {
          console.info("User canceled login or did not fully authorize.");
        }
      },
      //{
       // config_id: process.env.VUE_APP_FACEBOOK_CONFIG_ID,
      //  response_type: "token", // must be set to 'code' for System User access token
      //  override_default_response_type: true,
      //  scope: "public_profile,email,ads_read"
     // }
       {
         scope: 'email, public_profile, ads_read,page_events',
         appId: process.env.VUE_APP_FB_APP_ID,
       }
      // { auth_type: "reauthenticate" }
    );
  } catch (error) {
    console.error("Error during facebookSync", error);
  }
}
async function handleFacebookSyncnOut(event) {
  event.preventDefault();
  try {
    const data = {
      id: props.fbInfo.id,
      facebookStatus: false,
      projectId: props.projectId,
    };
    await updateFacebookInfoDetails(data);
    notifyParentToUpdateData();
  } catch (error) {
    console.error("Error during delete Dynamo Info facebookSyncnOut", error);
  }
  try {
    FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        FB.logout(function (response) {
          console.info("FB logout: ", response);
        });
      } else {
        console.info("User isn't logged in to Facebook.");
      }
    });
  } catch (error) {
    console.error("Error during facebookSyncnOut", error);
  }
}
onMounted(() => {
  if (props.fbInfo && props.fbInfo.facebookStatus) {
    userName.value = props.fbInfo.fbUserName;
    showLogoutButton.value = true;
  } else {
    showLogoutButton.value = false;
  }
});
watch(
  () => props.fbInfo,
  (newFbInfo) => {
    if (newFbInfo && newFbInfo.facebookStatus) {
      userName.value = newFbInfo.fbUserName;
      showLogoutButton.value = true;
    } else {
      showLogoutButton.value = false;
    }
  },
  { immediate: true }
);
</script>
<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
      <!-- <div class="text-center row">
        <div class="mx-auto col-10">
          <h5 class="font-weight-normal">Facebook Meta</h5>
          <p>
            Solicitamos que faça login com sua conta da Meta e escolha as contas
            de anúncios e campanhas que deseja conectar com a Ampliva.
          </p>
        </div>
      </div> -->
    <div class="multisteps-form__content">
      <!-- <div
        class="card-header card-photo-center p-0 position-relative mt-n4 mx-3 z-index-2"
      >
        <a href="#" class="d-block blur-shadow-image">
          <img
            :src="image"
            :alt="title"
            class="img-fluid-60 border-radius-lg"
          />
        </a>
      </div> -->
      <div class="card-body text-center">
        <h5 class="font-weight-normal">
          <a
            href="https://adsmanager.facebook.com/adsmanager/manage/campaigns"
            target="_blank"
            >{{ title }}</a
          >
        </h5>
        <p class="mb-0">
          {{ description }}
        </p>
        <div class="row pt-5">
          <div v-show="!showLogoutButton" class="col-md-12">
            <MaterialSocialButton
              route="https://www.facebook.com/sharer/sharer.php?u=https://ampliva.com/pt-br/"
              component="facebook-square"
              color="facebook"
              label="Login com Facebook"
              @click="submitFacebookLogin"
            />
          </div>
        </div>
        <div v-show="showLogoutButton" class="text-center">
          <MaterialButton
            class="center my-4 mb-2"
            variant="gradient"
            color="danger"
            @click="handleFacebookSyncnOut"
            >Logout Facebook</MaterialButton
          >
        </div>
        <div class="card-body text-center">
          <h5 ref="userNameElementRef" class="font-weight-normal">
            Usuário: {{ userName }}
          </h5>
        </div>
        <div v-if="showLogoutButton" class="pt-5">
          <FacebookAccountsTable
            :projectId="projectId"
            :userAdAccounts="userAdAccounts"
            :userAdCampaigns="userAdCampaigns"
            :fbInfo="fbInfo"
          />
        </div>
      </div>

      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-light js-btn-prev"
          type="button"
          title="Prev"
          @click="emit('prev-step')"
        >
          Prev
        </button>
      </div>
    </div>
  </div>
</template>
