/* global FB */
import axios from "axios";
import { useStore } from "vuex";
window.fbAsyncInit = function () {
  try {
    console.log("fbAsyncInit: ", process.env.VUE_APP_FB_APP_ID);
    FB.init({
      appId: process.env.VUE_APP_FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v18.0",
    });

    FB.AppEvents.logPageView();
  } catch (error) {
    console.error("Error during fbAsyncInit", error);
  }
};

(function (d, s, id) {
  try {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  } catch (error) {
    console.error("Error during fbAsyncInit", error);
  }
})(document, "script", "facebook-jssdk");

// TODO: TIRAR DO CLIENT SIDE, TALVEZ MOVER ISSO PARA UM LAMBDA POR CAUSA DOS SECRETS
export async function getLongLiveTokenWithFBSDK(access_token) {
  await FB.api(
    "oauth/access_token",
    {
      client_id: process.env.VUE_APP_FB_APP_ID,
      client_secret: process.env.VUE_APP_FB_APP_SECRET,
      grant_type: "fb_exchange_token",
      fb_exchange_token: access_token,
    },
    function (res) {
      if (!res || res.error) {
        console.error(!res ? "error occurred" : res.error);
      } else {
        var accessToken = res.access_token;
        if (accessToken != "undefined") {
          return accessToken;
        }
      }
      return null;
    }
  );
}

export function getLongLiveToken(access_token) {
  return new Promise((resolve, reject) => {
    try {
      const url = `https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${process.env.VUE_APP_FB_APP_ID}&client_secret=${process.env.VUE_APP_FB_APP_SECRET}&fb_exchange_token=${access_token}`;
      axios
        .get(url)
        .then((response) => {
          const longlivedAccessToken =
            response.data.access_token || access_token;
          resolve(longlivedAccessToken);
        })
        .catch((error) => {
          console.error("Error during getLongLivedTokenWithAxios ;;;;", error);
          reject(error);
        });
    } catch (error) {
      console.error("Error during getLongLivedTokenWithAxios ;;;;", error);
      reject(error);
    }
  });
}
export async function getLongLivedTokenWithAxios(access_token) {
  try {
    const url = `https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${process.env.VUE_APP_FB_APP_ID}&client_secret=${process.env.VUE_APP_FB_APP_SECRET}&fb_exchange_token=${access_token}`;
    const response = await axios.get(url);
    const longlivedAccessToken = response.data.access_token || access_token;
    return longlivedAccessToken;
  } catch (error) {
    console.error("Error during getLongLivedTokenWithAxios ;;;;", error);
    return error;
  }
}
export async function getAuthResponse() {
  try {
    const response = await FB.getAuthResponse();
    return response;
  } catch (error) {
    console.error("Error during getAuthResponse", error);
  }
}
// função que usa axios para acessar a graph API do facebook e chegar se volta o resultado ddo serviço /me/adaccounts para ver se ainda está valido o token do facebook
export async function getMeAdAccounts(accessToken) {
  try {
    const url = `https://graph.facebook.com/v18.0/me/adaccounts?access_token=${accessToken}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error("Error during getMeAdAccounts", error);
  }
}
export async function getMeWithFBSDK() {
  FB.api("/me", "GET", { fields: "name, picture" }, function (response) {
    if (response && response.error) {
      const store = useStore();
      const text =
        "Problema au buscar suas informações de usuário. Por favor, fale com seu consulto: " +
        response.error.message;
      store.dispatch("setAlertTextAndColor", {
        text,
        color: "danger",
      });
    }
  });
}
export async function getMeWithAxios(accessToken) {
  try {
    const url = `https://graph.facebook.com/v18.0/me?access_token=${accessToken}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error("Error during getMe", error);
    return error;
  }
}
// função que cria um token com expiração infinita para o facebook
export async function createLongLivedToken() {
  try {
    const url = `https://graph.facebook.com/v18.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${
      process.env.VUE_APP_FB_APP_ID
    }&client_secret=${process.env.VUE_APP_FB_APP_SECRET}&fb_exchange_token=${
      getAuthResponse().accessToken
    }`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error("Error during createLongLivedToken", error);
  }
}
// função que faz a revalidação do token do facebook automaticamente
export async function revalidateToken() {
  try {
    const url = `https://graph.facebook.com/v18.0/oauth/client_code?access_token=${
      getAuthResponse().accessToken
    }&client_secret=${process.env.VUE_APP_FB_APP_SECRET}&redirect_uri=${
      process.env.VUE_APP_FB_REDIRECT_URI
    }&client_id=${process.env.VUE_APP_FB_APP_ID}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error("Error during revalidateToken", error);
  }
}
