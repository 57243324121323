<script setup>
import FacebookAdAccountsTable from "./FacebookAdAccountsTable.vue";
import FacebookCampaignsTable from "./FacebookCampaignsTable.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import axios from "axios";
import {
  listAccountsByFacebookInfoIdAndCreatedAt,
  getFacebookAccountById,
  createAccount,
  updateAccount,
} from "@/services/adAccountsDataSource";
import {
  listCampaignsByFacebookAccountIdAndCreatedAt,
  createCampaign,
  updateCampaign,
  getCampaignById,
} from "@/services/campaignsDataSource";
import { ref, onMounted, watch } from "vue";

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  userAdAccounts: {
    type: Array,
    required: true,
  },
  userAdCampaigns: {
    type: Array,
    required: true,
  },
  fbInfo: {
    type: Object,
    required: true,
  },
});

const savedAccounts = ref([]);
const accountsRows = ref([]);
const campaignsRows = ref([]);
const savedCampaigns = ref([]);
const title = "Contas de anúncio do Facebook";

const fetchAccounts = async (newAccessToken) => {
  try {
    const facebookInfoId = props.fbInfo ? props.fbInfo.id : null;
    if (!facebookInfoId) {
      return;
    }
    const accounts = await listAccountsByFacebookInfoIdAndCreatedAt(
      facebookInfoId
    );
    savedAccounts.value = accounts;
    await fetchAdAccountsFromMeta(newAccessToken);
  } catch (error) {
    console.error("Error fetching accounts: ", error);
  }
};
const getDBAccountInfoIfAlreadyExists = (accountId) => {
  const account =
    savedAccounts.value &&
    savedAccounts.value.find((account) => account.accountId === accountId);
  return account;
};
const fetchAdAccountsFromMeta = async (newAccessToken) => {
  try {
    const accessToken = newAccessToken || props.fbInfo.accessToken;
    if (!accessToken) {
      return [];
    }
    const response = await axios.get(
      `https://graph.facebook.com/v18.0/me/adaccounts?fields=name,account_id,account_status,amount_spent,
      balance,id,business_name,business_state,business_city,business_street,business_zip&access_token=${accessToken}`
    );
    const adAccounts = response.data.data;
    const accountsWithCheckbox = adAccounts.map((account) => {
      const existingAccount = getDBAccountInfoIfAlreadyExists(account.id);
      let status = false;
      if (existingAccount) {
        status = existingAccount.accountStatus;
        account.createdAt = existingAccount.createdAt;
        account.dbId = existingAccount.id;
      }
      account.checked = status;
      account.connectionStatus = status;
      return account;
    });
    accountsRows.value = accountsWithCheckbox;
  } catch (error) {
    console.error("Error fetching ad accounts: ", error);
    return null;
  }
  await fetchAdCampaigns();
};
const changeSelectedAccountsRows = async (selectedRowsFromCheckbox) => {
  if (!selectedRowsFromCheckbox) {
    return;
  }
  accountsRows.value = selectedRowsFromCheckbox;
};
const handleSaveFacebookAccounts = async () => {
  for (const row of accountsRows.value) {
    const accountId = row.dbId;
    const alreadyExists = await checkIfAccountAlreadyExists(accountId);
    if (!row.checked) {
      if (alreadyExists) {
        const accountDetails = {
          id: accountId,
          accountId: row.id,
          name: row.name,
          facebookInfoId: props.fbInfo.id,
          accountStatus: false,
        };
        await updateFacebookAccount(accountDetails);
      } else {
        continue;
      }
    } else {
      if (alreadyExists) {
        if (alreadyExists.accountStatus) {
          continue;
        } else {
          const accountDetails = {
            id: accountId,
            accountId: row.id,
            name: row.name,
            facebookInfoId: props.fbInfo.id,
            accountStatus: true,
          };
          await updateFacebookAccount(accountDetails);
        }
      } else {
        const accountDetails = {
          accountId: row.id,
          name: row.name,
          facebookInfoId: props.fbInfo.id,
          accountStatus: true,
        };
        await saveFacebookAccount(accountDetails);
      }
    }
  }
};
const checkIfAccountAlreadyExists = async (accountId) => {
  try {
    const account = await getFacebookAccountById(accountId);
    return account;
  } catch (error) {
    console.error("Error listing account: ", error);
  }
};
const saveFacebookAccount = async (accountDetails) => {
  try {
    const newAccount = await createAccount(accountDetails);
    await fetchAccounts();
    return newAccount;
  } catch (error) {
    console.error("Error creating account: ", error);
    return error;
  }
};
const updateFacebookAccount = async (accountDetails) => {
  try {
    const updatedAccount = await updateAccount(accountDetails);
    await fetchAccounts();
    return updatedAccount;
  } catch (error) {
    console.error("Error updating account: ", error);
    return error;
  }
};
// const deleteFacebookAccount = async (accountId) => {
//   try {
//     const deletedAccount = await deleteAccount(accountId);
//   } catch (error) {
//     console.error("Error deleting account: ", error);
//   }
// };
const fetchAdCampaigns = async () => {
  savedCampaigns.value = [];
  campaignsRows.value = [];
  try {
    if (!accountsRows.value || accountsRows.value.length == 0) {
      return;
    }
    accountsRows.value.forEach(async (account) => {
      if (!account.checked) {
        return;
      }
      const adCampaigns = await listCampaignsByFacebookAccountIdAndCreatedAt(
        account.dbId
      );
      savedCampaigns.value = adCampaigns;
      await fetchAdCampaignsFromMeta(
        account.id,
        account.dbId,
        props.fbInfo.accessToken
      );
    });
  } catch (error) {
    console.error("Error fetching ad campaigns: ", error);
  }
};
const getDBCampaignInfoIfAlreadyExists = (campaignId) => {
  const campaign =
    savedCampaigns.value &&
    savedCampaigns.value.find((campaign) => campaign.campaignId === campaignId);
  return campaign;
};
const fetchAdCampaignsFromMeta = async (accountId, accDBId, accessToken) => {
  try {
    const response = await axios.get(
      `https://graph.facebook.com/v18.0/${accountId}/campaigns?fields=id,name,objective,pacing_type,primary_attribution,
      created_time,start_time,status,stop_time,updated_time,source_campaign,promoted_object,lifetime_budget,effective_status,
      daily_budget,buying_type,account_id&access_token=${accessToken}`
    );
    const adCampaigns = response.data.data;
    const campaignsWithCheckbox = adCampaigns.map((campaign) => {
      const existingCampaign = getDBCampaignInfoIfAlreadyExists(campaign.id);
      let status = false;
      if (existingCampaign) {
        status = existingCampaign.campaignStatus;
        campaign.dbId = existingCampaign.id;
        campaign.createdAt = existingCampaign.createdAt;
      }
      campaign.facebookAccountId = accDBId;
      campaign.checked = status;
      campaign.connectionStatus = status;
      return campaign;
    });
    campaignsRows.value = [...campaignsRows.value, ...campaignsWithCheckbox];
  } catch (error) {
    console.error("Error fetching ad campaigns: ", error);
    return null;
  }
};
const changeSelectedCampaignsRows = async (selectedRowsFromCheckbox) => {
  if (!selectedRowsFromCheckbox) {
    return;
  }
  campaignsRows.value = selectedRowsFromCheckbox;
};
const handleSaveFacebookAdCampaigns = async () => {
  for (const row of campaignsRows.value) {
    const campaignId = row.dbId;
    const alreadyExists = await checkIfCampaignAlreadyExists(campaignId);
    if (!row.checked) {
      if (alreadyExists) {
        const campaignDetails = {
          id: campaignId,
          campaignId: row.id,
          name: row.name,
          effectiveStatus: row.effective_status,
          buyingType: row.buying_type,
          facebookAccountId: row.facebookAccountId,
          campaignStatus: false,
        };
        await updateFacebookAdCampaign(campaignDetails);
      } else {
        continue;
      }
    } else {
      if (alreadyExists) {
        if (alreadyExists.campaignStatusStatus) {
          continue;
        } else {
          const campaignDetails = {
            id: campaignId,
            campaignId: row.id,
            name: row.name,
            effectiveStatus: row.effective_status,
            buyingType: row.buying_type,
            facebookAccountId: row.facebookAccountId,
            campaignStatus: true,
          };
          await updateFacebookAdCampaign(campaignDetails);
        }
      } else {
        const campaignDetails = {
          campaignId: row.id,
          name: row.name,
          effectiveStatus: row.effective_status,
          buyingType: row.buying_type,
          facebookAccountId: row.facebookAccountId,
          campaignStatus: true,
        };
        await saveFacebookAdCampaign(campaignDetails);
      }
    }
  }
  await fetchAdCampaigns();
};
const checkIfCampaignAlreadyExists = async (fbInfoId) => {
  const currentCampaign = await getAdCampaignById(fbInfoId);
  return currentCampaign && currentCampaign.id == fbInfoId;
};
const saveFacebookAdCampaign = async (campaignDetails) => {
  try {
    await createCampaign(campaignDetails);
  } catch (error) {
    console.error("Error creating campaign: ", error);
  }
};
const updateFacebookAdCampaign = async (campaignDetails) => {
  try {
    await updateCampaign(campaignDetails);
  } catch (error) {
    console.error("Error updating campaign: ", error);
  }
};
const getAdCampaignById = async (fbInfoId) => {
  try {
    const campaign = await getCampaignById(fbInfoId);
    return campaign;
  } catch (error) {
    console.error("Error listing campaign: ", error);
  }
};
// const deleteFacebookAdCampaign = async (campaignId) => {
//   try {
//     const deletedCampaign = await deleteCampaign(campaignId);
//   } catch (error) {
//     console.error("Error deleting campaign: ", error);
//   }
// };
onMounted(async () => {
  if (props.fbInfo && props.fbInfo.accessToken) {
    await fetchAccounts();
  }
});
watch(
  [() => props.fbInfo],
  async (newFbInfo) => {
    if (!newFbInfo) {
      return;
    }
    // await fetchAccounts(newFbInfo.accessToken);
  },
  { immediate: true }
);
</script>
<template>
  <div v-if="props.fbInfo">
    <div class="card-body text-center">
      <h5 class="font-weight-normal">
        {{ title }}
      </h5>
    </div>
    <FacebookAdAccountsTable
      :rows="accountsRows"
      @update-selected-rows="changeSelectedAccountsRows"
    ></FacebookAdAccountsTable>
    <div class="text-center px-3">
      <MaterialButton
        class="center my-4 mb-2"
        variant="gradient"
        color="success"
        @click="handleSaveFacebookAccounts"
        >Salvar</MaterialButton
      >
    </div>
    <div class="pt-3">
      <div class="card-body text-center">
        <h5 class="font-weight-normal">Campanhas das contas de anúncio</h5>
      </div>
      <FacebookCampaignsTable
        :rows="campaignsRows"
        @update-selected-rows="changeSelectedCampaignsRows"
      ></FacebookCampaignsTable>
      <div class="text-center px-3">
        <MaterialButton
          class="center my-4 mb-2"
          variant="gradient"
          color="success"
          @click="handleSaveFacebookAdCampaigns"
          >Salvar</MaterialButton
        >
      </div>
    </div>
  </div>
</template>