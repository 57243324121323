<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  directionReverse: {
    type: Boolean,
    default: false,
  },
  selectedItem: {
    type: Object,
    default: null,
  },
  itemList: {
    type: Array,
    default: () => [],
  },
  allItensText: {
    type: String,
    default: "Todas",
  },
  title: {
    type: [Object, String],
    default: null,
    text: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  subtitle: {
    type: [Object, String],
    default: null,
    text: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  value: {
    type: [Object, String, Number],
    required: true,
    text: {
      type: [String, Number],
    },
    color: {
      type: String,
    },
  },
  percentage: {
    type: [Object, String],
    value: {
      type: String,
    },
    color: {
      type: String,
    },
    default: () => ({
      color: "text-success",
    }),
  },
  classContent: {
    type: String,
    default: "",
  },
});
const selected = ref(props.selectedItem);
watch(() => {
  selected.value = props.selectedItem;
});
const emits = defineEmits(["update:selectedItem"]);
const selectItem = (item) => {
  selected.value = item;
  emits("update:selectedItem", item);
};
// const data = reactive({
//   reverseDirection: "flex-row-reverse justify-content-between",
// });
</script>
<template>
  <div class="mb-4 card">
    <div class="p-3 card-body">
      <div
        class="row"
        :class="directionReverse ? reverseDirection : 'justify-content-between'"
      >
        <div class="col-md-12">
          <div class="mb-3">
            <label for="projectSelect" class="form-label">{{
              title.text
            }}</label>
            <select
              id="projectSelect"
              v-model="selected"
              class="form-select"
              @change="selectItem(selected)"
            >
              <option v-if="!selectedItem">Escolha...</option>
              <option value="Todas" selected>{{ allItensText }}</option>
              <option v-for="item in itemList" :key="item.id" :value="item">
                {{ item.projectName ? item.projectName : item }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="subtitle"
          class="text-end mt-2"
          :class="classContent ? classContent : ''"
        >
          <div class="numbers">
            <p
              class="mb-0 text-sm text-capitalize"
              :class="subtitle ? subtitle.color : ''"
            >
              {{
                typeof subtitle === "string"
                  ? subtitle
                  : subtitle
                  ? subtitle.text
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
