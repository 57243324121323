/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cognitoId
      name
      email
      projects {
        nextToken
        __typename
      }
      providers {
        nextToken
        __typename
      }
      fbInfos {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cognitoId
      name
      email
      projects {
        nextToken
        __typename
      }
      providers {
        nextToken
        __typename
      }
      fbInfos {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      cognitoId
      name
      email
      projects {
        nextToken
        __typename
      }
      providers {
        nextToken
        __typename
      }
      fbInfos {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectName
      companyName
      bspCode
      bspId
      bsp {
        id
        createdAt
        updatedAt
        name
        code
        __typename
      }
      providerId
      provider {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      fbInfoId
      fbInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectName
      companyName
      bspCode
      bspId
      bsp {
        id
        createdAt
        updatedAt
        name
        code
        __typename
      }
      providerId
      provider {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      fbInfoId
      fbInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectName
      companyName
      bspCode
      bspId
      bsp {
        id
        createdAt
        updatedAt
        name
        code
        __typename
      }
      providerId
      provider {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      fbInfoId
      fbInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $input: CreateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    createProvider(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      providerStatus
      solutionId
      wabaId
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $input: UpdateProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    updateProvider(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      providerStatus
      solutionId
      wabaId
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider(
    $input: DeleteProviderInput!
    $condition: ModelProviderConditionInput
  ) {
    deleteProvider(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      providerStatus
      solutionId
      wabaId
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createFacebookInfo = /* GraphQL */ `
  mutation CreateFacebookInfo(
    $input: CreateFacebookInfoInput!
    $condition: ModelFacebookInfoConditionInput
  ) {
    createFacebookInfo(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      facebookStatus
      accessToken
      fbUserId
      fbUserName
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      facebookAccounts {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateFacebookInfo = /* GraphQL */ `
  mutation UpdateFacebookInfo(
    $input: UpdateFacebookInfoInput!
    $condition: ModelFacebookInfoConditionInput
  ) {
    updateFacebookInfo(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      facebookStatus
      accessToken
      fbUserId
      fbUserName
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      facebookAccounts {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteFacebookInfo = /* GraphQL */ `
  mutation DeleteFacebookInfo(
    $input: DeleteFacebookInfoInput!
    $condition: ModelFacebookInfoConditionInput
  ) {
    deleteFacebookInfo(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      facebookStatus
      accessToken
      fbUserId
      fbUserName
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      facebookAccounts {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createFacebookAccount = /* GraphQL */ `
  mutation CreateFacebookAccount(
    $input: CreateFacebookAccountInput!
    $condition: ModelFacebookAccountConditionInput
  ) {
    createFacebookAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountStatus
      accountId
      name
      facebookInfoId
      FacebookInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      facebookCampaigns {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateFacebookAccount = /* GraphQL */ `
  mutation UpdateFacebookAccount(
    $input: UpdateFacebookAccountInput!
    $condition: ModelFacebookAccountConditionInput
  ) {
    updateFacebookAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountStatus
      accountId
      name
      facebookInfoId
      FacebookInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      facebookCampaigns {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteFacebookAccount = /* GraphQL */ `
  mutation DeleteFacebookAccount(
    $input: DeleteFacebookAccountInput!
    $condition: ModelFacebookAccountConditionInput
  ) {
    deleteFacebookAccount(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      accountStatus
      accountId
      name
      facebookInfoId
      FacebookInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      facebookCampaigns {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createFacebookCampaign = /* GraphQL */ `
  mutation CreateFacebookCampaign(
    $input: CreateFacebookCampaignInput!
    $condition: ModelFacebookCampaignConditionInput
  ) {
    createFacebookCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      campaignStatus
      campaignId
      name
      effectiveStatus
      buyingType
      facebookAccountId
      FacebookAccount {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateFacebookCampaign = /* GraphQL */ `
  mutation UpdateFacebookCampaign(
    $input: UpdateFacebookCampaignInput!
    $condition: ModelFacebookCampaignConditionInput
  ) {
    updateFacebookCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      campaignStatus
      campaignId
      name
      effectiveStatus
      buyingType
      facebookAccountId
      FacebookAccount {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteFacebookCampaign = /* GraphQL */ `
  mutation DeleteFacebookCampaign(
    $input: DeleteFacebookCampaignInput!
    $condition: ModelFacebookCampaignConditionInput
  ) {
    deleteFacebookCampaign(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      campaignStatus
      campaignId
      name
      effectiveStatus
      buyingType
      facebookAccountId
      FacebookAccount {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createBSP = /* GraphQL */ `
  mutation CreateBSP(
    $input: CreateBSPInput!
    $condition: ModelBSPConditionInput
  ) {
    createBSP(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      code
      __typename
    }
  }
`;
export const updateBSP = /* GraphQL */ `
  mutation UpdateBSP(
    $input: UpdateBSPInput!
    $condition: ModelBSPConditionInput
  ) {
    updateBSP(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      code
      __typename
    }
  }
`;
export const deleteBSP = /* GraphQL */ `
  mutation DeleteBSP(
    $input: DeleteBSPInput!
    $condition: ModelBSPConditionInput
  ) {
    deleteBSP(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      code
      __typename
    }
  }
`;
