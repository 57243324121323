<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  id: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "dark",
  },
  inputClass: {
    type: String,
    default: "",
  },
  labelClass: {
    type: String,
    default: "",
  },
  checked: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update"]);

const updateChecked = (event) => {
  emit("update", event.target);
};
</script>
<template>
  <div class="ps-0 form-check">
    <input
      :id="id"
      class="form-check-input"
      :class="`bg-${color} border-${color} ${inputClass}`"
      type="checkbox"
      value=""
      :checked="checked"
      @change="updateChecked"
    />
    <label class="form-check-label" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>
