<script setup>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { onMounted, computed, ref, nextTick } from "vue";
import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";

const userName = ref("");
const userEmail = ref("");
const confirmationEmail = ref("");
const showConfirmationCode = ref(false);
const confirmationCode = ref("");
const toastMsg = ref("ok");
const toastColor = ref("primary");
const showToast = ref(false);
const alertRef = ref(null);
const authStore = useAuthStore();
const {
  handleFetchUserAttributes,
  handleUpdateUserAttribute,
  handleConfirmUserAttribute,
} = authStore;
const { user } = storeToRefs(authStore);
if (user.value) {
  userName.value = user.value.userName;
  if (user.value.signInDetails) {
    userEmail.value = user.value.signInDetails.loginId;
  }
}
onMounted(() => {
  initUserInfos();
});
const initUserInfos = () => {
  const attributes = handleFetchUserAttributes();
  attributes.then((resp) => {
    const name = resp.name;
    const email = resp.email;
    userName.value = name;
    userEmail.value = email;
  });
};
const handleUpdateName = (event) => {
  event.preventDefault();
  handleUpdateUserAttribute("name", userName.value);
};
const showUpdateNameButton = computed(() => {
  return user.value && userName.value !== user.value.userName;
});
const showUpdateEmailButton = computed(() => {
  return (
    user.value &&
    userEmail.value !== user.value.email &&
    !showConfirmationCode.value
  );
});
const handleUpdateEmail = async (event) => {
  if (userEmail.value !== confirmationEmail.value) {
    showInformationToast("danger", "Emails do not match");
    return;
  }
  event.preventDefault();
  const resp = await handleUpdateUserAttribute("email", userEmail.value);
  if (resp) {
    showConfirmationCode.value = true;
    showInformationToast(
      "secondary",
      "Confirmation code sent to your new email: " + userEmail.value
    );
  } else {
    showInformationToast("danger", "Error updating email");
  }
};
const handleUpdateEmailConfirmationCode = async (event) => {
  event.preventDefault();
  const resp = await handleConfirmUserAttribute(
    "email",
    confirmationCode.value
  );
  if (resp) {
    showConfirmationCode.value = true;
    showInformationToast("success", "Email updated successfully!");
  } else {
    showInformationToast("danger", "Error confirming updating email code");
  }
};
const showInformationToast = (color, msg) => {
  toastColor.value = color;
  toastMsg.value = msg;
  showToast.value = true;
  nextTick(() => {
    const element = alertRef.value.$el;
    element.focus();
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  });
  setTimeout(() => {
    showToast.value = false;
  }, 5 * 1000);
};
</script>
<template>
  <div id="basic-info" class="card mt-4">
    <div v-show="showToast">
      <MaterialAlert
        id="alertId"
        ref="alertRef"
        v-focus
        class="cursor-pointer"
        :color="toastColor"
        fontWeight="bold"
        focusable="true"
        @click="showToast = false"
        >{{ toastMsg }}</MaterialAlert
      >
    </div>
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="col-6">
        <MaterialInput
          id="firstName"
          v-model="userName"
          variant="static"
          label="First Name"
          placeholder="Your name"
        />
      </div>
    </div>
    <div v-show="showUpdateNameButton" class="card-footer">
      <MaterialButton
        variant="gradient"
        color="dark"
        type="submit"
        class="float-end mt-2 mb-0"
        size="sm"
        @click="handleUpdateName"
        >Change Name</MaterialButton
      >
    </div>
    <div class="card-body pt-0">
      <div class="row mt-4">
        <div class="col-6">
          <MaterialInput
            id="email"
            v-model="userEmail"
            type="email"
            variant="static"
            label="Email"
            placeholder="example@email.com"
          />
        </div>
        <div class="col-6">
          <MaterialInput
            id="confirmEmail"
            v-model="confirmationEmail"
            type="email"
            variant="static"
            label="Confirm Email"
            placeholder="example@email.com"
          />
        </div>
      </div>
    </div>
    <div v-show="showUpdateEmailButton" class="card-footer">
      <MaterialButton
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="handleUpdateEmail"
        >Update email</MaterialButton
      >
    </div>

    <div v-if="showConfirmationCode" class="card-body pt-0">
      <div class="row mt-4">
        <div class="col-6">
          <MaterialInput
            id="confirmationCode"
            v-model="confirmationCode"
            type="text"
            variant="static"
            label="Confirm code from email"
            placeholder="xxxxxxx"
          />
        </div>
        <div class="card-footer">
          <MaterialButton
            class="float-end mt-6 mb-0"
            color="dark"
            variant="gradient"
            size="sm"
            @click="handleUpdateEmailConfirmationCode"
            >Confirmation code</MaterialButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
