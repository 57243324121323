<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth/auth";
import SidenavItem from "../Sidenav/SidenavItem.vue";
import logoAmplivaWhisper from "@/assets/img/logos/logo-ampliva-whisper-navbar.png";
import logoAmpliva from "@/assets/img/logos/logo-ampliva.png";

const showMenu = ref(false);
const showLoginMenu = ref(false);
const logo = ref(logoAmplivaWhisper);

const router = useRouter();
const route = useRoute();
const getRoute = () => {
  if (!route) return;
  const routeArr = route.path.split("/");
  return routeArr[1];
};
const checkRoute = (route) => {
  const currentRoute = getRoute();
  return route === currentRoute;
};
const props = defineProps({
  btnText: {
    type: String,
    default: "DEMONSTRAÇÃO",
  },
  btnBackground: {
    type: String,
    default: "bg-white",
  },
  isBlur: {
    type: String,
    default: "blur-dark",
  },
  darkMode: {
    type: Boolean,
    default: false,
  },
  //customersSectionRef: {
  //  type: String,
  //  default: "",
 // },
  aboutSectionRef: {
    type: String,
    default: "",
  },
  featuresSectionRef: {
    type: String,
    default: "",
  },
  contactSectionRef: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  const authStore = useAuthStore();
  if (!authStore.isAuthenticated) {
    showMenu.value = false;
    showLoginMenu.value = true;
  }
  if (window.innerWidth <= 768) {
    logo.value = logoAmpliva;
  } else {
    logo.value = logoAmplivaWhisper;
  }
});
window.addEventListener("resize", () => {
  if (window.innerWidth <= 768) {
    logo.value = logoAmpliva;
  } else {
    logo.value = logoAmplivaWhisper;
  }
});
const scrollToSection = (elementRef) => {
  nextTick(() => {
    const element = elementRef;
    if (!element) return;
    try {
      element.focus();
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } catch (error) {
      console.error("Error focusing on element: ", error);
    }
  });
};
//const scrollToCustomersSection = () => {
//  scrollToSection(props.customersSectionRef);
//};
const scrollToAboutSection = () => {
  scrollToSection(props.aboutSectionRef);
};
const scrollToFeaturesSection = () => {
  scrollToSection(props.featuresSectionRef);
};
const scrollToContactSection = () => {
  scrollToSection(props.contactSectionRef);
};
const handleContactBtn = async () => {
  if (checkRoute("login")) {
    console.log("login>>>>>>");
    await router.push({ name: "Home" });
  } else {
    scrollToContactSection();
  }
};
</script>
<template>
  <nav
    class="top-0 navbar navbar-expand-lg position-absolute z-index-3 blur-dark"
    :class="isBlur ? isBlur : 'shadow-none my-3 navbar-transparent w-100 mt-4'"
  >
    <div class="container" :class="isBlur && 'px-1'">
      <router-link
        class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
        :class="darkMode ? 'text-black' : 'text-white'"
        :to="showLoginMenu ? '/' : '/overview'"
      >
        <img
          v-show="!showIcon"
          id="full-logo"
          :src="logo"
          class="pt-4 navbar-brand-img min-vh-10"
          alt="main_logo"
          style="margin-top: -1.5rem"
      /></router-link>
      <button
        class="shadow-none navbar-toggler ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navigation"
        aria-controls="navigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="mt-2 navbar-toggler-icon">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </span>
      </button>
      <div
        id="navigation"
        class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0"
      >
        <ul class="mx-auto navbar-nav navbar-nav-hover">
          <SidenavItem
            v-if="!checkRoute('login')"
            :class="checkRoute('createProject') ? 'active' : ''"
            text="Sobre nós"
            text-color="text-white"
            @click="scrollToAboutSection"
          />
          <SidenavItem
            v-if="!checkRoute('login')"
            :class="checkRoute('providers') ? 'active' : ''"
            text="Funcionalidades"
            text-color="text-white"
            @click="scrollToFeaturesSection"
          />
        </ul>
        <li
          v-if="showLoginMenu && !checkRoute('login')"
          class="nav-item active align-self-center no-marker ms-3"
        >
          <router-link class="nav-link" :to="'/login'">
            <a class="mb-0 btn btn-sm bg-white">Login</a>
          </router-link>
        </li>
        <li class="nav-item align-self-center no-marker ms-sm-4">
          <a
            target="_blank"
            class="mb-0 btn btn-sm me-1"
            :class="btnBackground"
            @click="handleContactBtn"
            >{{ btnText }}</a
          >
        </li>
      </div>
    </div>
  </nav>
</template>
