<script setup>
import { onMounted } from "vue";
import setMaterialInput from "@/assets/js/material-input.js";

defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  variant: {
    type: String,
    default: "outline",
  },
  label: {
    type: String,
    default: "",
  },
  textColor: {
    type: String,
    default: "text-dark",
  },
  size: {
    type: String,
    default: "default",
  },
  success: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
function emitValue(e) {
  let value = e.target.value;
  emit("update:modelValue", value);
}
onMounted(setMaterialInput);

const getClasses = (size, textColor) => {
  let sizeValue;
  let variantValue;
  sizeValue = size ? `form-control-${size}` : null;

  if(textColor){
    variantValue = `${sizeValue} ${textColor}`;
  }
  return variantValue;
};

const getLabelClasses = (variant, textColor) => {
  let variantValue;

  variantValue = variant === "static" ? "" : "form-label";

  if(textColor){
    variantValue = `${variantValue} ${textColor}`;
  }

  return variantValue;
};

const getStatus = (error, success) => {
  let isValidValue;

  if (success) {
    isValidValue = "is-valid";
  } else if (error) {
    isValidValue = "is-invalid";
  } else {
    isValidValue = null;
  }

  return isValidValue;
};
</script>
<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <label :class="getLabelClasses(variant, textColor)">{{ label }}</label>
    <input
      :id="id"
      :type="type"
      class="form-control"
      :class="getClasses(size, textColor)"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="isDisabled"
      :value="modelValue"
      @input="emitValue"
    />
  </div>
</template>
