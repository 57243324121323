<script setup>
import { ref, computed, onMounted, watchEffect } from "vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

const selectedAccount = ref("");
const selectedCampaign = ref("");
const selectedAdset = ref("");
const selectedAd = ref("");
const selectedDateStart = ref("");
const selectedDateStop = ref("");
const useCustomFilters = ref(false);
const selfData = ref([]);

onMounted(() => {
  selfData.value = props.data.value;
});
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

watchEffect(() => {
  useCustomFilters.value = !useCustomFilters.value;
});
const filteredData = computed(() => {
  return useCustomFilters.value && useCustomFilters.value.length
    ? selfData.value.filter((item) => {
        return (
          item &&
          (selectedAccount.value === "Todas" ||
            item.account_name === selectedAccount.value) &&
          (selectedCampaign.value === "Todas" ||
            item.campaign_name === selectedCampaign.value) &&
          (selectedAdset.value === "Todas" ||
            item.adset_name === selectedAdset.value) &&
          (selectedAd.value === "Todas" || item.ad_name === selectedAd.value) &&
          (selectedDateStart.value === "Todas" ||
            item.date_start >= selectedDateStart.value) &&
          (selectedDateStop.value === "Todas" ||
            item.date_stop <= selectedDateStop.value)
        );
      })
    : props.data;
});

const countFirstStep = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_1) || 0,
    0
  )
);
const countFifithStep = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_5) || 0,
    0
  )
);
// const countImpressions = computed(() =>
//   filteredData.value.reduce((total, item) => Number(total) + Number(item.impressions), 0)
// );
const countSpend = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.spend),
    0
  )
);
// const countClicks = computed(() =>
//   filteredData.value.reduce((total, item) => Number(total) + Number((item.link_click || 0)), 0)
// );
// const countCPC = computed(() => countSpend.value / (countClicks.value || 1));
const costFirstStep = computed(
  () => countSpend.value / (countFirstStep.value || 1)
);
const costFifithStep = computed(
  () => countSpend.value / (countFifithStep.value || 1)
);
</script>

<template>
  <section id="count-stats" class="pt-3 pb-4">
    <div>
      <div class="row">
        <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <mini-statistics-card
                :title="{
                  text: 'Step 1',
                  value: countFirstStep,
                }"
                detail="<span class='text-success text-sm font-weight-bolder'></span> Eventos da primeira etapa do funil"
                :icon="{
                  name: 'groups',
                  color: 'text-white',
                  background: 'dark',
                }"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
              <mini-statistics-card
                :title="{
                  text: 'Custo Step 1',
                  value: costFirstStep,
                  prefix: 'R$ ',
                  decimals: 2,
                }"
                detail="<span class='text-success text-sm font-weight-bolder'></span> Custo por eventos da primeira etapa"
                :icon="{
                  name: 'currency_exchange',
                  color: 'text-white',
                  background: 'dark',
                }"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
              <mini-statistics-card
                :title="{ text: 'Step 5', value: countFifithStep }"
                detail="<span class='text-success text-sm font-weight-bolder'></span> Eventos da última etapa do funil"
                :icon="{
                  name: 'shopping_cart',
                  color: 'text-white',
                  background: 'dark',
                }"
              />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <mini-statistics-card
                :title="{
                  text: 'Custo step 5',
                  value: costFifithStep,
                  prefix: 'R$ ',
                  decimals: 2,
                }"
                detail="<span class='text-success text-sm font-weight-bolder'></span> Custo por eventos da última etapa do funil"
                :icon="{
                  name: 'currency_exchange',
                  color: 'text-white',
                  background: 'dark',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
