<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- <div
      class="mask bg-gradient-dark"
      style="
        background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
      "class="bg-gradient-dark opacity-8"
    > -->
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <div class="">
          <img
            v-show="showIcon"
            :src="
              sidebarType === 'bg-white' ||
              (sidebarType === 'bg-transparent' && !isDarkMode)
                ? logoAmpliva
                : logoAmpliva
            "
            class="navbar-brand-img h-100"
            alt="main_logo"
          />
          <span class="ms-1 font-weight-bold text-white min-vh-100-px">
            <img
              v-show="!showIcon"
              id="full-logo"
              :src="logoAmplivaWhisper"
              class="navbar-brand-img min-vh-100-px"
              alt="main_logo"
              style="margin-top: -1.5rem"
            />
          </span>
        </div>
      </router-link>
    </div>
    <sidenav-list />
    <!-- </div> -->
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logos/logo-ct.png";
import logoDark from "@/assets/img/logos/logo-ct-dark.png";
import logoAmpliva from "@/assets/img/logos/logo-ampliva.png";
import logoAmplivaWhisper from "@/assets/img/logos/logo-ampliva-whisper.png";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      logoAmpliva,
      logoAmplivaWhisper,
      isImageInViewport: false,
      showIcon: true,
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode", "isPinned"]),
  },
  watch: {
    isPinned(newVal) {
      this.showIcon = !newVal;
    },
  },
  mounted() {
    if (this.isPinned) {
      this.showIcon = false;
    }
  },
  methods: {
    mouseEnter() {
      this.showIcon = false;
    },
    mouseLeave() {
      if (!this.isPinned) {
        this.showIcon = true;
      } else {
        this.showIcon = false;
      }
    },
  },
};
</script>
