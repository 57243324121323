<script setup>
import { ref, computed, onMounted, watch } from "vue";
// import { Chart, LinearScale, CategoryScale } from "chart.js";
// import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";

// import ChartDataLabels from "chartjs-plugin-datalabels";
import ApexCharts from "apexcharts";

// Chart.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);

// let chart = null;
const props = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
});
const dataRef = ref(props.data);
const countFirstStep = computed(() =>
  dataRef.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_1) || 0,
    0
  )
);
const countSecondStep = computed(() =>
  dataRef.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_2) || 0,
    0
  )
);
const countThirdStep = computed(() =>
  dataRef.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_3) || 0,
    0
  )
);
const countFourthStep = computed(() =>
  dataRef.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_4) || 0,
    0
  )
);
const countFifithStep = computed(() =>
  dataRef.value.reduce(
    (total, item) => Number(total) + Number(item.etapa_5) || 0,
    0
  )
);
// const createChart = () => {
//   if (!document.getElementById("funnelChart")) {
//     return;
//   }
//   const ctx = document.getElementById("funnelChart").getContext("2d");
//   if (chart) {
//     chart.destroy();
//   }
//   let gradient = ctx.createLinearGradient(0, 0, 0, 700);
//   gradient.addColorStop(0, "rgba(53, 221, 156, 0.9)");
//   gradient.addColorStop(1, "rgba(55, 39, 132, 0)");
//   const chartConfig = {
//     type: "funnel",
//     data: {
//       labels: ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"],
//       datasets: [
//         {
//           label: "",
//           borderColor: "rgba(199, 199, 199, 0.3)",
//           backgroundColor: gradient,
//           borderWidth: 2,
//           hoverBackgroundColor: "rgba(112, 215, 221, 0.9)",
//           hoverBorderColor: "rgba(112, 215, 221, 1)",
//           hoverBorderWidth: 3,
//           hoverRadius: 5,
//           pointStyle: "circle",
//           radius: 4,
//           hitRadius: 10,
//           fill: true,
//           data: [
//             countFirstStep.value,
//             countSecondStep.value,
//             countThirdStep.value,
//             countFourthStep.value,
//             countFifithStep.value,
//           ],
//         },
//       ],
//     },
//     options: {
//       indexAxis: "y",
//       title: {
//         display: true,
//         text: "Funil completo",
//       },
//       legend: {
//         display: true,
//         labels: {
//           fontColor: "rgb(255, 99, 132)",
//         },
//       },
//       tooltips: {
//         enabled: true,
//         mode: "index",
//         intersect: false,
//         callbacks: {
//           label: function (tooltipItem, data) {
//             var label = data.datasets[tooltipItem.datasetIndex].label || "";
//             if (label) {
//               label += ">> ";
//             }
//             label += Math.round(tooltipItem.yLabel * 100) / 100;
//             if (typeof label === "string" && label.includes("%")) {
//               label = label.replace("%", "");
//             }
//             return label;
//           },
//         },
//       },
//       plugins: {
//         datalabels: {
//           formatter: (value, context) => {
//             const dataset = context.chart.data.datasets[context.datasetIndex];
//             const labels = context.chart.data.labels;
//             const step = labels[context.dataIndex];
//             const title = dataset.label;
//             if (typeof value === "string" && value.includes("%")) {
//               value = value.replace("%", "");
//             }
//             return `${step}\n${title}${value}`;
//           },
//           color: "rgba(100, 100, 100, 0.95)",
//           backgroundColor: "rgba(255, 255, 255, 0.4)",
//           borderColor: "rgba(255, 255, 255, 0.2)",
//           borderWidth: 1,
//           font: {
//             size: 14,
//             weight: "bold",
//           },
//           padding: 4,
//           rotation: 0,
//         },
//       },
//     },
//     plugins: [ChartDataLabels],
//   };
//   chart = new Chart(ctx, chartConfig);
//   return chart;
// };
let funnelChart = null;
let chartRef = ref(null);
const makeFunnel = () => {
  if (!chartRef.value) {
    return;
  }
  if (funnelChart) {
    funnelChart.destroy();
  }
  const getLastValue = () => {
    if (!countFifithStep.value || countFifithStep.value === 0) {
      return countFifithStep.value;
    } else {
      if (countFifithStep.value <= 30 && countFourthStep.value > 30) {
        return 30;
      } else {
        return countFifithStep.value;
      }
    }
  };
  function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const options = {
    chart: {
      type: "bar",
      height: 450,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        isFunnel: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        if (opts.dataPointIndex === 4) {
          return formatNumber(countFifithStep.value);
        } else {
          return formatNumber(val);
        }
        // return opts.w.globals.series[opts.seriesIndex][
        //   opts.dataPointIndex
        // ].toLocaleString("pt-BR");
      },
    },
    tooltip: {
      y: {
        formatter: function (val, opts) {
          if (opts.dataPointIndex === 4) {
            return formatNumber(countFifithStep.value);
          } else {
            return formatNumber(val);
          }
        },
      },
    },
    series: [
      {
        name: "Leads",
        data: [
          {
            x: "Passo 1",
            y: countFirstStep.value,
          },
          {
            x: "Passo 2",
            y: countSecondStep.value,
          },
          {
            x: "Passo 3",
            y: countThirdStep.value,
          },
          {
            x: "Passo 4",
            y: countFourthStep.value,
          },
          {
            x: "Passo 5",
            y: getLastValue(),
          },
        ],
      },
    ],
  };

  funnelChart = new ApexCharts(chartRef.value, options);
  funnelChart.render();
};
watch([() => props.data], ([newData]) => {
  dataRef.value = newData;
  // createChart();
  makeFunnel();
});
onMounted(() => {
  // createChart();
  makeFunnel();
});
</script>

<template>
  <section id="count-stats" class="pt-3 pb-4">
    <div class="container card">
      <div class="row">
        <h4 class="text-center pt-3">Funil completo</h4>
        <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
          <div ref="chartRef"></div>
        </div>
      </div>
      <!-- <div class="row">
        <h4 class="text-center pt-3">Funil completo</h4>
        <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
          <canvas id="funnelChart"></canvas>
        </div>
      </div> -->
    </div>
  </section>
</template>
