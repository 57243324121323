import { generateClient } from "@aws-amplify/api";
import {
  createFacebookAccount,
  updateFacebookAccount,
  deleteFacebookAccount,
} from "@/graphql/mutations";
import {
  listFacebookAccounts,
  facebookAccountsByFacebookInfoIdAndCreatedAt,
  getFacebookAccount,
} from "@/graphql/queries";

export const createAccount = async (accountDetails = {}) => {
  try {
    
    const client = generateClient();
    const response = await client.graphql({
      query: createFacebookAccount,
      variables: { input: accountDetails },
    });
    return response.data.createFacebookAccount;
  } catch (error) {
    console.error("Error createAccount", error);
    return null;
  }
};

export const updateAccount = async (accountDetails = {}) => {
  try {
    
    const client = generateClient();
    const response = await client.graphql({
      query: updateFacebookAccount,
      variables: { input: accountDetails },
    });
    const updatedAccount = response.data.updateFacebookAccount;
    return updatedAccount;
  } catch (error) {
    console.error("Error updateAccount", error);
    return null;
  }
};

export const deleteAccount = async (accountId) => {
  try {
    
    const client = generateClient();
    const response = await client.graphql({
      query: deleteFacebookAccount,
      variables: { input: { id: accountId } },
    });
    return response.data.deleteFacebookAccount;
  } catch (error) {
    console.error("Error deleteAccount", error);
    return null;
  }
};

export const listAccounts = async () => {
  try {
    
    const client = generateClient();
    const response = await client.graphql({
      query: listFacebookAccounts,
    });
    return response.data.listFacebookAccounts.items;
  } catch (error) {
    console.error("Error listAccounts", error);
    return null;
  }
};
export const getFacebookAccountById = async (accountId) => {
  try {
    if(!accountId) {
      console.info("accountId is null");
      return null;
    }
    const client = generateClient();
    const response = await client.graphql({
      query: getFacebookAccount,
      variables: { id: accountId },
    });
    return response.data.getFacebookAccount;
  } catch (error) {
    console.error("Error getFacebookAccountById", error);
    return null;
  }
};

export const listAccountsByFacebookInfoIdAndCreatedAt = async (
  facebookInfoId,
  createdAt
) => {
  try {
    
    const client = generateClient();
    const response = await client.graphql({
      query: facebookAccountsByFacebookInfoIdAndCreatedAt,
      variables: { facebookInfoId, createdAt },
    });
    const accounts = response.data.facebookAccountsByFacebookInfoIdAndCreatedAt;
    return accounts.items;
  } catch (error) {
    console.error("Error listAccountsByFacebookInfoIdAndCreatedAt", error);
    return null;
  }
};
