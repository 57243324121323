<script setup>
import { ref, onMounted, watch } from "vue";
import MaterialButton from "@/components/MaterialButton.vue";
import BasicInfoCard from "./BasicInfoCard.vue";
import PasswordCard from "./PasswordCard.vue";

import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";
import setMaterialInput from "@/assets/js/material-input";

const userName = ref("");
const userEmail = ref("");
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const { signOut } = authStore;
const {
  handleFetchUserAttributes,
} = authStore;
if(user.value){
  userName.value = user.value.userName;
}
watch(user, (newValue) => {
  userName.value = newValue ? newValue.userName : '';
}, { immediate: true });
const initUserInfo = () => {
  const attributes = handleFetchUserAttributes();
  attributes.then((resp) => {
    const name = resp.name;
    const email = resp.email;
    userName.value = name;
    userEmail.value = email;
  });
};
async function handleSignOut(event) {
  try {
    await signOut(event);
  } catch (error) {
    console.error("Error during signOut", error);
  }
}
onMounted(() => {
  setMaterialInput();
  initUserInfo();
});
</script>
<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <!-- <material-avatar
          :img="img"
          alt="bruce"
          size="xl"
          shadow="sm"
          circular
        /> -->
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ userName }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ userEmail }}</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <div class="d-block">
          <MaterialButton
            class="text-nowrap mb-0"
            variant="outline"
            color="danger"
            size="sm"
            @click="handleSignOut"
            >SignOut</MaterialButton
          >
        </div>
      </div>
    </div>
  </div>
  <BasicInfoCard />
  <PasswordCard />
</template>
