import { generateClient } from "@aws-amplify/api";
import {
  createFacebookInfo,
  updateFacebookInfo,
  deleteFacebookInfo,
} from "@/graphql/mutations";
import { listFacebookInfos } from "@/graphql/queries";
import { listFacebookFullInfos } from "@/graphql/customQueries";
import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";
import { updateProjectDetails } from "./projectsDataSource";

export const createNewFacebookInfo = async (facebookInfoDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    facebookInfoDetails.userId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: createFacebookInfo,
      variables: { input: facebookInfoDetails },
    });
    const createdFacebookInfo = response.data.createFacebookInfo;
    await updateProject(
      facebookInfoDetails.projectId,
      createdFacebookInfo.id
    );
    return createdFacebookInfo;
  } catch (error) {
    console.error("Error creating facebookInfo: ", error);
    throw error;
  }
};
const updateProject = async (projectId, fbInfoId) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    const projectDetails = {
      id: projectId,
      fbInfoId,
      userId,
    };
    const updatedProject = await updateProjectDetails(projectDetails);
    return updatedProject;
  } catch (error) {
    console.error("Error updating project: ", error);
    throw error;
  }
};
export const updateFacebookInfoDetails = async (facebookInfoDetails = {}) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: updateFacebookInfo,
      variables: { input: facebookInfoDetails },
    });
    const updatedFacebookInfo = response.data.updateFacebookInfo;
    await updateProject(
      facebookInfoDetails.projectId,
      facebookInfoDetails.facebookStatus ? facebookInfoDetails.id : null
    );
    return updatedFacebookInfo;
  } catch (error) {
    console.error("Error updating facebookInfo: ", error);
    throw error;
  }
};
export const deleteFacebookInfoById = async (projectId, facebookInfoId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: deleteFacebookInfo,
      variables: { input: { id: facebookInfoId } },
    });
    const deletedFacebookInfo = response.data.deleteFacebookInfo;
    await updateProject(projectId, "");
    return deletedFacebookInfo;
  } catch (error) {
    console.error("Error deleting facebookInfo: ", error);
    throw error;
  }
};
export const getFacebookInfos = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listFacebookInfos,
      variables: { limit: 1000 },
    });
    const facebookInfos = response.data.listFacebookInfos.items;
    return facebookInfos;
  } catch (error) {
    console.error("Error listing facebookInfos: ", error);
    throw error;
  }
};
export const getFacebookFullInfos = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listFacebookFullInfos,
      variables: { limit: 1000 },
    });
    const facebookInfos = response.data.listFacebookInfos.items;
    return facebookInfos;
  } catch (error) {
    console.error("Error listing facebookInfos: ", error);
    return null;
  }
};
export const getLastFacebookInfo = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listFacebookInfos,
      variables: { limit: 1 },
    });
    const facebookInfos = response.data.listFacebookInfos.items;
    return facebookInfos[0];
  } catch (error) {
    console.error("Error listing facebookLastInfo: ", error);
    return null;
  }
};
