<script setup>
import { ref, onMounted } from "vue";
import { getFacebookFullInfos } from "@/services/facebookDataSource";
import MetaAccountTableComponent from "./MetaAccountTableComponent.vue";

const fbData = ref([]);
const formatDate = (date) => {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("pt-BR", options);
};
const countActiveAccounts = (fbAccounts) => {
  const activeAccounts = fbAccounts.filter(account => account.accountStatus);
  return activeAccounts.length;
};
const countActiveCampaigns = (fbAccounts) => {
  return fbAccounts.reduce((total, account) => {
    const activeCampaigns = account.facebookCampaigns.items.filter(campaign => campaign.campaignStatus);
    return total + activeCampaigns.length;
  }, 0);
};
const checkAllAccounts = async (facebookInfos) => {
  facebookInfos.forEach(async (facebook) => {
    const fbAccounts = facebook.facebookAccounts ? facebook.facebookAccounts.items : [];
    const account = {
      projectName: facebook.fbUserId,
      fbUserName: facebook.fbUserName,
      status:
        !!facebook.fbUserId && !!facebook.projectId && facebook.facebookStatus,
      accounts: countActiveAccounts(fbAccounts),
      campaigns: countActiveCampaigns(fbAccounts),
      date: formatDate(facebook.createdAt),
      action: {
        label: "Ver",
        route: facebook.projectId,
      },
    };
    fbData.value = [...fbData.value, account];
  });
};
onMounted(async () => {
  const facebookInfos = await getFacebookFullInfos();
  checkAllAccounts(facebookInfos);
});
</script>
<template>
  <section>
    <div class="container">
      <div class="row align-items-center">
        <MetaAccountTableComponent :rows="fbData"></MetaAccountTableComponent>
      </div>
    </div>
  </section>
</template>
