<script setup>
import { ref, onMounted, onUnmounted, nextTick } from "vue";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import Header from "@/examples/Header.vue";
import heroAmpliva from "@/assets/img/top_banner_whisper_home.png";
import featureCard from "@/assets/img/feature_card_home.png";
import techCard from "@/assets/img/whisper_tech_provider.png";
import LandingPageInfoCard from "@/examples/Cards/LandingPageInfoCard.vue";
import CenteredBlogCard from "@/examples/Cards/CenteredBlogCard.vue";
import AboutUsOption from "./AboutUsOption.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import setMaterialInput from "@/assets/js/material-input";
import { useStore } from "vuex";
import Typed from "typed.js";
import { useRouter } from "vue-router";

const store = useStore();
const body = document.getElementsByTagName("body")[0];

// const customersSectionRef = ref(null);
const aboutSectionRef = ref(null);
const featuresSectionRef = ref(null);
const contactSectionRef = ref(null);
const router = useRouter();

onMounted(() => {
  setMaterialInput();
  body.classList.add("presentation-page");
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
  // body.classList.remove("bg-gray-200");
  body.classList.add("about-us");
  // body.classList.add("bg-gray-200");

  if (document.getElementById("typed")) {
    // eslint-disable-next-line no-unused-vars
    var typed = new Typed("#typed", {
      stringsElement: "#typed-strings",
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });
  }
  if (document.getElementById("emailTrackRef")) {
    document.getElementById("emailTrackRef").onchange = function () {
      myFunctionUTM(router);
    };
  }
});

function myFunctionUTM(router) {
  const currentPath = router.currentRoute.value;
  const params = currentPath.query;
  const utm_campaign_capt = params.utm_campaign;
  const utm_medium_capt = params.utm_medium;
  const utm_source_capt = params.utm_source;
  const utm_content_capt = params.utm_content;
  const utm_term_capt = params.utm_term;

  document.getElementById("utm_campaign").value = utm_campaign_capt;
  document.getElementById("utm_medium").value = utm_medium_capt;
  document.getElementById("utm_source").value = utm_source_capt;
  document.getElementById("utm_content").value = utm_content_capt;
  document.getElementById("utm_term").value = utm_term_capt;
}

onUnmounted(() => {
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
  body.classList.remove("presentation-page");
  body.classList.add("bg-gray-200");
});
function mascaraTelefone(event) {
  let tecla = event.key;
  let telefone = event.target.value.replace(/\D+/g, "");

  if (/^[0-9]$/i.test(tecla)) {
    telefone = telefone + tecla;
    let tamanho = telefone.length;

    if (tamanho >= 12) {
      return false;
    }

    if (tamanho > 10) {
      telefone = telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (tamanho > 5) {
      telefone = telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (tamanho > 2) {
      telefone = telefone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      telefone = telefone.replace(/^(\d*)/, "($1");
    }

    event.target.value = telefone;
  }

  if (!["Backspace", "Delete"].includes(tecla)) {
    return false;
  }
}
const scrollToSection = (elementRef) => {
  nextTick(() => {
    const element = elementRef;
    element.focus();
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  });
};
const scrollToContactSection = () => {
  scrollToSection(contactSectionRef.value);
};
const goToPage = () => {
  window.open("https://www.facebook.com/business/partner-directory/search?solution_type=campaign_management&ref=pd_home_hero_cta&id=3486771791433309&section=overview", "_blank");
};
</script>

<template>
  <div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur my-3 py-2 mt-4 start-0 end-0 mx-4 shadow blur border-radius-lg"
          btn-background="bg-gradient-primary"
          :dark-mode="true"
          :aboutSectionRef="aboutSectionRef"
          :featuresSectionRef="featuresSectionRef"
          :contactSectionRef="contactSectionRef"
        />
      </div>
    </div>
  </div>
  <Header>
    <section>
      <div
        class="pt-7 page-header min-vh-65"
        :style="{ backgroundImage: `url(${heroAmpliva})` }"
        loading="lazy"
      >
        <span class="mask bg-gradient-secondary opacity-2"></span>
        <div class="container">
          <div class="row justify-content-center">
            <h1 class="pt-6 text-white text-8xl">Medição de</h1>
            <div class="col-lg-5 me-auto my-auto">
              <h2 class="text-white">
                <span id="typed" class="text-white"></span>
              </h2>
              <div id="typed-strings">
                <h2>investimento</h2>
                <h2>conversões</h2>
                <h2>funil completo</h2>
              </div>
              <h4 class="pt-7 mb-4 text-white text-bolder">
                Com o Whisper, você pode realizar uma análise detalhada do funil completo de conversões das suas campanhas de Click to WhatsApp, 
                garantindo precisão e embasamento para tomar decisões mais assertivas.
              </h4>
              <!-- <p class="lead pt-3 mb-4 text-white text-bolder">
                Tenha as atribuições de conversões de campanhas de Click To
                Whatsapp mensuradas com precisão.
              </p> -->
              <div class="py-5">
                <button
                  type="submit"
                  class="btn bg-white text-dark"
                  @click="scrollToContactSection"
                >
                  DEMONSTRAÇÃO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Header>
  <!-- comment <section ref="customersSectionRef">
      <div class="py-7">
        <div class="container col-lg-10">
          <div class="row">
            <div class="col-8 mx-auto text-center">
              <h3 class="opacity-5">Empresas que confiam na Ampliva</h3>
            </div>
          </div>
          <div class="row mt-4 align-items-center">
            <div class="col-lg-3 col-md-4 col-6 mb-4">
              <img
                class="w-100 opacity-9"
                src="@/assets/img/logos/logo-ailos.svg"
                alt="logo"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-4">
              <img
                class="w-100 opacity-9"
                src="@/assets/img/logos/logo-euphoria-agency.gif"
                alt="logo"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-4">
              <img
                class="w-100 opacity-9"
                src="@/assets/img/logos/logo-meta.png"
                alt="logo"
              />
            </div>
            <div class="col-lg-3 col-md-4 col-6 mb-4">
              <img
                class="w-80 opacity-9"
                src="@/assets/img/logos/logo-lewlara-vertical-1.webp"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
    </section> -->
  <section ref="aboutSectionRef">
    <div class="py-7 page-header min-vh-65">
      <span class="mask bg-gradient-secondary opacity-8"></span>
      <div class="container col-lg-10">
        <div class="row align-items-center">
          <div class="col-lg-6 pe-lg-5 ms-auto mt-lg-0 mt-6">
            <CenteredBlogCard
              :image="featureCard"
              @click="scrollToContactSection"
            />
          </div>
          <div class="col-lg-6 ps-5 pt-5">
            <div class="row justify-content-start">
              <h1 class="text-white">
                Plataforma de mensuração para campanhas de Click to Whatsapp
              </h1>
              <LandingPageInfoCard
                class="text-white"
                col="col-lg-12"
                title="Nossa plataforma é especializada em atribuir corretamente as conversões geradas dentro de um bot conversacional. Com integrações perfeitas entre Ads Manager, seu Bot e Web Analytics, garantimos uma arbitragem precisa e detalhada das conversões, fornecendo insights valiosos para o seu negócio."
                description=""
              />
              <div class="col-lg-6 pt-4 justify-content-center">
                <button
                  type="submit"
                  class="btn bg-primary text-white"
                  @click="scrollToContactSection"
                >
                  DEMONSTRAÇÃO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="">
    <div class="container py-5">
      <div class="row align-items-center">
        <div class="col-md-6 mb-md-0 mb-4">
          <h3>Somos Parceiros de Negócios e Tecnologia da Meta</h3>
          <p class="lead mb-md-5 mb-4 pt-4">
            Ampliva é um parceiro oficial da Meta, especializado em marketing de performance. Oferecemos soluções proprietárias projetadas para impulsionar os resultados do seu negócio em um ambiente digital em constante evolução. 
            Como <b>Business Partner</b> e <b>Tech Provider Global da Meta</b>, estamos comprometidos em fornecer as melhores estratégias e ferramentas para alcançar o sucesso online.
          </p>
        </div>
        <div class="col-md-6">
          <div class="blur-shadow-image text-center">
            <img
              :src="techCard"
              alt="img-shadow"
              class="img-fluid shadow-xl border-radius-lg max-height-500 cursor-pointer"
              @click="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section ref="featuresSectionRef">
    <div class="container col-lg-12 py-7">
      <div class="row">
        <div class="my-auto">
          <h2>Funcionalidades</h2>
          <h4 class="pe-5">
            Desenvolvido para atender às necessidades de agências e anunciantes que buscam uma medição precisa de suas campanhas de Click To WhatsApp.
          </h4>
        </div>
        <div
          class="row col-lg-12 mt-lg-0 mt-5 pt-5 ps-lg-0 ps-0 justify-content-center"
        >
          <div class="row col-6">
            <AboutUsOption
              :icon="{ component: 'equalizer', color: 'quaternary' }"
              content="Dashboards customizados"
            />
            <AboutUsOption
              icon="mediation"
              content="Integração com Ads Manager"
            />
            <AboutUsOption
              icon="auto_graph"
              content="Integração com Web Analytics"
            />
            <AboutUsOption icon="insights" content="Análises de performance" />
          </div>
          <div class="row col-6">
            <AboutUsOption
              icon="multiline_chart"
              content="Configuração de CAPI"
            />
            <AboutUsOption
              icon="donut_large"
              content="Configuração de Web Analytics"
            />
            <AboutUsOption
              icon="edit_attributes"
              content="Atribuições de conversões completas"
            />
            <AboutUsOption icon="phone" content="Whatsapp web analytics" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section ref="contactSectionRef">
    <div class="py-7 page-header min-vh-40">
      <span class="mask bg-gradient-quaternary opacity-6"></span>
      <div class="container col-lg-10">
        <div class="row">
          <div class="col-md-6 m-auto">
            <h2 class="text-white">Solicite uma demonstração</h2>
            <p class="mb-4 text-white">
              Envie-nos seus dados de contato para agendarmos 
              uma apresentação completa da nossa plataforma e prepararmos uma proposta personalizada para você.
            </p>
            <div class="row">
              <div class="col-12">
                <form
                  id="form_1"
                  method="POST"
                  action="https://ampliva40592.activehosted.com/proc.php"
                >
                  <input type="hidden" name="u" value="1" />
                  <input type="hidden" name="f" value="1" />
                  <input type="hidden" name="s" />
                  <input type="hidden" name="c" value="0" />
                  <input type="hidden" name="m" value="0" />
                  <input type="hidden" name="act" value="sub" />
                  <input type="hidden" name="v" value="2" />

                  <input
                    id="utm_source"
                    type="hidden"
                    name="field[12]"
                    value=""
                  />
                  <input
                    id="utm_medium"
                    type="hidden"
                    name="field[13]"
                    value=""
                  />
                  <input
                    id="utm_campaign"
                    type="hidden"
                    name="field[14]"
                    value=""
                  />
                  <input
                    id="utm_content"
                    type="hidden"
                    name="field[15]"
                    value=""
                  />
                  <input
                    id="utm_term"
                    type="hidden"
                    name="field[16]"
                    value=""
                  />

                  <div class="active-form">
                    <div class="form-block">
                      <label for="fullname" class="_form-label"> </label>
                      <input
                        id="fullname"
                        type="text"
                        name="fullname"
                        data-name="fullname"
                        placeholder="Digite nome completo"
                        required
                      />
                    </div>

                    <div class="form-block emailform">
                      <label for="email" class="_form-label"> </label>
                      <input
                        id="emailTrackRef"
                        type="email"
                        name="email"
                        data-name="email"
                        placeholder="Digite seu e-mail"
                        required
                      />
                    </div>

                    <label for="phone" class="_form-label"> </label>
                    <div class="_form-label">
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Digite seu telefone"
                        required
                        :onkeydown="mascaraTelefone"
                      />
                    </div>
                    <div class="form-block">
                      <button>Enviar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-5 ms-auto my-auto md-mt-0">
            <div class="position-relative">
              <img
                class="max-width-50 w-100 position-relative z-index-2"
                src="@/assets/img/whisper-preview.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <AppFooter class="pt-7" />
</template>
<style>
form * {
  box-sizing: border-box;
}

.active-form {
  padding: 0px;
  /* background: white; */
  position: relative;
  width: 100%;
}

.active-form input[type="text"],
.active-form input[type="email"] {
  margin: -5px 0px;
  background: white;
  font-size: 16px;
  color: #757575;
  font-weight: 400;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  font-family: Lato, Montserrat, Sans-serif;
  padding: 8px 18px;
  vertical-align: middle;
  line-height: 1.4;
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 5%);
}

.active-form input::placeholder {
  color: #757575;
  background-color: #fff;
  font-family: Lato, Montserrat, Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.form-block button {
  background: #01b619 !important;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 20px auto;
  display: block;
  padding: 10px 0px;
  text-transform: uppercase;
  width: 100%;
  font-family: Lato, Montserrat, Sans-serif;
  font-weight: 900;
  font-size: 18px;
}
</style>
