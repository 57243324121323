<script setup>
import { useStore } from "vuex";
import setTooltip from "@/assets/js/tooltip.js";
import MaterialInput from "@/components/MaterialInput.vue";

import { onMounted, ref, nextTick, computed, watch } from "vue";
// import { useRouter } from "vue-router";
import {
  createNewProject,
  updateProjectDetails,
  // getProjectById,
} from "@/services/projectsDataSource";
import {
  //  getBSPSFromDB,
  createNewBSP,
} from "@/services/bspDataSource";
import setMaterialInput from "@/assets/js/material-input";

const store = useStore();
const newBSPText = "Outro"

const projectNameInputText = ref("Nome do Projeto");
const companyNameInputText = ref("Nome da Empresa");
const userEnteredBSPInputText = ref("Digite o código do seu BSP");
const editText = ref("Habilitar edição");
const projectNameInput = ref("");
const companyNameInput = ref("");
const bspInput = ref("");
const userEnteredBSPInput = ref("");
const enableProjectInputs = ref(true);
const bspsList = ref([]);
const projectId = ref("");
const isEditPage = ref(false);
const emit = defineEmits(["next-step", "update-project-id"]);

const props = defineProps({
  propProjectId: {
    type: String,
    required: false,
    default: "",
  },
  projectName: {
    type: String,
    required: false,
    default: "",
  },
  companyName: {
    type: String,
    required: false,
    default: "",
  },
  bspCode: {
    type: String,
    required: false,
    default: "",
  },
  bsps: {
    type: Array,
    required: false,
    default: () => [],
  },
});
onMounted(() => {
  setTooltip(store.state.bootstrap);
  setMaterialInput();
  projectId.value = props.propProjectId;
  projectNameInput.value = props.projectName;
  companyNameInput.value = props.companyName;
  bspsList.value = props.bsps;
  bspInput.value = props.bspCode;
  enableProjectInputs.value = !projectId.value;
});
const getBSPCodeFromInput = computed(() => {
  return isOtherOptionSelected.value
    ? userEnteredBSPInput.value
    : bspInput.value.code || bspInput.value;
});
const projectDetails = computed(() => {
  return {
    projectName: projectNameInput.value,
    companyName: companyNameInput.value,
    bspCode: getBSPCodeFromInput.value,
    bspId: bspInput.value.id || bspInput.value,
  };
});
const isOtherOptionSelected = computed(() => {
  if (bspInput.value && bspInput.value == newBSPText) {
    const isBspCodeInList =
      bspsList.value.filter((bsp) => {
        return bsp.code == projectDetails.value.bspCode;
      }).length > 0;
    return !isBspCodeInList;
  }
  return false;
});
watch([props], ([newProps]) => {
  if (newProps && newProps.propProjectId) {
    projectId.value = newProps.propProjectId;
    projectNameInput.value = newProps.projectName;
    companyNameInput.value = newProps.companyName;
    bspInput.value = newProps.bspCode;
  }
  enableProjectInputs.value = !projectId.value;
  bspsList.value = newProps.bsps;
});
const showSaveButton = computed(() => {
  return (
    projectDetails.value.projectName &&
    projectDetails.value.companyName &&
    projectDetails.value.bspCode &&
    enableProjectInputs.value && 
    (getBSPCodeFromInput.value != newBSPText)
  );
});
const showEditButton = computed(() => {
  return !!projectId.value;
});
const submitForm = async function (event) {
  event.preventDefault();
  const hastAllProjectDetails =
    projectDetails.value.projectName &&
    projectDetails.value.companyName &&
    projectDetails.value.bspCode;
  if (!hastAllProjectDetails) {
    const text = "Preencha todos os campos!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "danger",
    });
    return;
  }
  if (isOtherOptionSelected.value) {
    try {
      const bspData = {
        name: "Novo BSP criado pelo cliente: " + userEnteredBSPInput.value,
        code: userEnteredBSPInput.value,
      };
      const newBSP = await createNewBSP(bspData);
      bspInput.value = { id: newBSP.id, name: newBSP.name, code: newBSP.code };
    } catch (error) {
      const text = "Error creating new BSP: " + error;
      console.error(text);
      store.dispatch("setAlertTextAndColor", {
        text,
        color: "danger",
      });
      return;
    }
  }
  try {
    if (projectId.value) {
      await updateProject();
    } else {
      await createProject();
    }
    nextTick(() => {
      try {
        emit("next-step");
      } catch (error) {
        console.error("Error scrolling to solutionIdSectionRef: ", error);
      }
    });
  } catch (error) {
    console.error("Error on submit project form: ", error);
  }
};
const createProject = async () => {
  try {
    const newProject = await createNewProject(projectDetails.value);
    projectId.value = newProject.id;
    enableProjectInputs.value = false;
    // initProject();
    emit("update-project-id", projectId.value);
  } catch (error) {
    console.error("Error creating project: ", error);
  }
};
const updateProject = async () => {
  try {
    const updateInput = projectDetails.value;
    updateInput["id"] = projectId.value;
    await updateProjectDetails(updateInput);
    enableProjectInputs.value = false;
    // initProject();
    emit("update-project-id", projectId.value);
  } catch (error) {
    console.error("Error updating project: ", error);
  }
};
const toggleEdit = () => {
  event && event.preventDefault();
  enableProjectInputs.value = !enableProjectInputs.value;
  editText.value = !enableProjectInputs.value ? "Habilitar edição" : "Cancelar";
};
</script>
<template>
  <div
    class="pt-3 bg-white multisteps-form__panel js-active position-relative"
    data-animation="FadeIn"
  >
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">
          Vamos começar com as informações básicas
        </h5>
        <p>
          Crie um nome para o projeto e defina a empresa associada. Por fim,
          escolha um de nossos provedores de soluções de negócios já conectados
          ou crie um novo e aguarde enquanto nós o conectamos adequadamente.
        </p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12 col-sm-4"></div>
        <div class="mt-4 col-12 col-sm-12 mt-sm-0 text-start">
          <div class="d-block mb-4">
            <material-input
              id="firstName"
              v-model="projectNameInput"
              variant="static"
              class="input-group-static mb-4"
              :label="projectNameInputText"
              :isDisabled="!enableProjectInputs"
              type="text"
            />
          </div>
          <div class="d-block mb-4">
            <material-input
              id="lastName"
              v-model="companyNameInput"
              variant="static"
              class="input-group-static"
              :label="companyNameInputText"
              :isDisabled="!enableProjectInputs"
              type="text"
            />
          </div>
          <div class="d-block mb-4">
            <div class="col-md-6">
              <label>Selecione o seu BSP</label>
              <select
                v-model="bspInput"
                class="form-select"
                aria-label="Default select example"
                :disabled="!enableProjectInputs"
              >
                <option v-for="bsp in bspsList" :key="bsp.id" :value="bsp">
                  {{ bsp.name }}
                </option>
                <option>{{ newBSPText }}</option>
              </select>
            </div>
            <div v-show="isOtherOptionSelected" class="col-md-6">
              <MaterialInput
                v-model="userEnteredBSPInput"
                class="input-group-static"
                :label="userEnteredBSPInputText"
                type="text"
                :is-disabled="!enableProjectInputs"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          v-if="showEditButton"
          class="mb-0 btn bg-gradient-warning js-btn-prev"
          type="button"
          title="Edit"
          @click="toggleEdit"
        >
          {{ editText }}
        </button>
        <button
          v-if="showSaveButton"
          class="mb-0 btn bg-gradient-success ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="submitForm"
        >
          Save & Next
        </button>
        <button
          v-if="!showSaveButton && isEditPage"
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="emit('next-step')"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
