<script setup>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { useStore } from "vuex";
import { ref, defineExpose, defineEmits, watch } from "vue";

const email = ref('')
const store = useStore();
const emit = defineEmits(['email-submitted'])
const bootstrap = store.state.bootstrap;
const props = defineProps({
  emailInput: {
    type: String,
    required: false,
    default: ''
  }
})
watch(() => {
  email.value = props.emailInput;
});
// eslint-disable-next-line no-unused-vars
const openModal = () => {
  var myModal = new bootstrap.Modal(document.getElementById("emailModal"));
  myModal.show();
};
// eslint-disable-next-line no-unused-vars
const closeModal = () => {
  var myModal = bootstrap.Modal.getInstance(
    document.getElementById("emailModal")
  );
  myModal.hide();
};
const submitEmail = () => {
  emit('email-submitted', email.value)
  closeModal()
}
defineExpose({
  openModal,
  closeModal
})
</script>
<template>
  <div
    id="emailModal"
    class="modal modal-custom fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">Confirmar email</h5>
          <MaterialButton
            color="none"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
          </MaterialButton>
        </div>
        <div class="modal-body">
          <label for="email-input">Por favor, digite seu email para verificação:</label>
          <MaterialInput id="email-input" v-model="email" type="email" class="form-control" placeholder="Email"/>
        </div>
        <div class="modal-footer justify-content-between">
          <MaterialButton
            variant="gradient"
            color="dark"
            data-bs-dismiss="modal"
          >
            Cancelar
          </MaterialButton>
          <MaterialButton variant="gradient" color="success" class="mb-0" @click="submitEmail">
            Verificar
          </MaterialButton>
        </div>
      </div>
    </div>
  </div>
</template>
