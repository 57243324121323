/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      createdAt
      updatedAt
      cognitoId
      name
      email
      projects {
        nextToken
        __typename
      }
      providers {
        nextToken
        __typename
      }
      fbInfos {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByCognitoIdAndCreatedAt = /* GraphQL */ `
  query UsersByCognitoIdAndCreatedAt(
    $cognitoId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCognitoIdAndCreatedAt(
      cognitoId: $cognitoId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      createdAt
      updatedAt
      projectName
      companyName
      bspCode
      bspId
      bsp {
        id
        createdAt
        updatedAt
        name
        code
        __typename
      }
      providerId
      provider {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      fbInfoId
      fbInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByUserIdAndCreatedAt = /* GraphQL */ `
  query ProjectsByUserIdAndCreatedAt(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      createdAt
      updatedAt
      providerStatus
      solutionId
      wabaId
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const providersByUserIdAndCreatedAt = /* GraphQL */ `
  query ProvidersByUserIdAndCreatedAt(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providersByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFacebookInfo = /* GraphQL */ `
  query GetFacebookInfo($id: ID!) {
    getFacebookInfo(id: $id) {
      id
      createdAt
      updatedAt
      facebookStatus
      accessToken
      fbUserId
      fbUserName
      userId
      user {
        id
        createdAt
        updatedAt
        cognitoId
        name
        email
        owner
        __typename
      }
      projectId
      project {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        providerId
        fbInfoId
        userId
        owner
        __typename
      }
      facebookAccounts {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listFacebookInfos = /* GraphQL */ `
  query ListFacebookInfos(
    $filter: ModelFacebookInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facebookInfosByUserIdAndCreatedAt = /* GraphQL */ `
  query FacebookInfosByUserIdAndCreatedAt(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFacebookInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facebookInfosByUserIdAndCreatedAt(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFacebookAccount = /* GraphQL */ `
  query GetFacebookAccount($id: ID!) {
    getFacebookAccount(id: $id) {
      id
      createdAt
      updatedAt
      accountStatus
      accountId
      name
      facebookInfoId
      FacebookInfo {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        __typename
      }
      facebookCampaigns {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listFacebookAccounts = /* GraphQL */ `
  query ListFacebookAccounts(
    $filter: ModelFacebookAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facebookAccountsByFacebookInfoIdAndCreatedAt = /* GraphQL */ `
  query FacebookAccountsByFacebookInfoIdAndCreatedAt(
    $facebookInfoId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFacebookAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facebookAccountsByFacebookInfoIdAndCreatedAt(
      facebookInfoId: $facebookInfoId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFacebookCampaign = /* GraphQL */ `
  query GetFacebookCampaign($id: ID!) {
    getFacebookCampaign(id: $id) {
      id
      createdAt
      updatedAt
      campaignStatus
      campaignId
      name
      effectiveStatus
      buyingType
      facebookAccountId
      FacebookAccount {
        id
        createdAt
        updatedAt
        accountStatus
        accountId
        name
        facebookInfoId
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listFacebookCampaigns = /* GraphQL */ `
  query ListFacebookCampaigns(
    $filter: ModelFacebookCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookCampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        campaignStatus
        campaignId
        name
        effectiveStatus
        buyingType
        facebookAccountId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facebookCampaignsByFacebookAccountIdAndCreatedAt = /* GraphQL */ `
  query FacebookCampaignsByFacebookAccountIdAndCreatedAt(
    $facebookAccountId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFacebookCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facebookCampaignsByFacebookAccountIdAndCreatedAt(
      facebookAccountId: $facebookAccountId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        campaignStatus
        campaignId
        name
        effectiveStatus
        buyingType
        facebookAccountId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBSP = /* GraphQL */ `
  query GetBSP($id: ID!) {
    getBSP(id: $id) {
      id
      createdAt
      updatedAt
      name
      code
      __typename
    }
  }
`;
export const listBSPS = /* GraphQL */ `
  query ListBSPS(
    $filter: ModelBSPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBSPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        code
        __typename
      }
      nextToken
      __typename
    }
  }
`;
