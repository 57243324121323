<script setup>
import MaterialBadge from "@/components/MaterialBadge.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  headers: {
    type: Array,
    default: () => [
      "Projeto  -  Empresa",
      "BSP  -  Whatasapp  -  Facebook",
      "Status",
      "Criado em",
      "Visualizar",
      "Ações",
    ],
  },
  rows: {
    type: Array,
    required: true,
    id: String,
    projectName: String,
    companyName: String,
    connectionInfo: {
      type: Array,
      default: () => [],
    },
    status: Boolean,
    date: String,
    view: {
      type: Object,
      viewLabel: String,
      viewRoute: String,
      default: () => {
        return {
          viewLabel: "Dashboard",
          viewRoute: "#",
        };
      },
    },
    action: {
      type: Object,
      label: String,
      route: String,
      default: () => {
        return {
          label: "Edit",
          route: "#",
        };
      },
    },
  },
});
const gotoDashboard = (id) => {
  router.push({ name: "Dashboard", params: { id  } });
};
const gotoEdit = (id) => {
  router.push({ name: "EditProject", params: { id, step: "project"  } });
};
</script>
<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="header"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              :class="{ 'ps-2': index == 1, 'text-center': index > 1 }"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              {
                projectName,
                companyName,
                connectionInfo: [bspCode, providerId, fbInfoId],
                status,
                date,
                view: { viewLabel, viewRoute },
                action: { label, route },
              },
              index
            ) of props.rows"
            :key="index"
          >
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                </div>
              </div>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-xs">{{ projectName }}</h6>
                  <p class="text-xs text-secondary mb-0">
                    {{ companyName }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{ bspCode }}</p>
              <p class="text-xs text-secondary mb-0">{{ providerId }}</p>
              <p class="text-xs text-secondary mb-0">{{ fbInfoId }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <MaterialBadge
                variant="gradient"
                :color="status ? 'success' : 'danger'"
              >
                {{ status ? "Online" : "Offline" }}
              </MaterialBadge>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{
                date
              }}</span>
            </td>
            <td class="align-middle text-center">
              <a
                class="btn btn-sm mt-3 text-secondary text-success font-weight-bold text-xs"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click="gotoDashboard(viewRoute)"
              >
                {{ viewLabel }}
              </a>
            </td>
            <td class="align-middle text-center">
              <a
                class="btn btn-sm mt-3 text-secondary text-warning font-weight-bold text-xs"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click="gotoEdit(route)"
              >
                {{ label }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
