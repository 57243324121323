<script setup>
// example component
import DefaultCounterCard from "@/examples/Cards/CounterCards/DefaultCounterCard.vue";
import NewProject from "./NewProject.vue";
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const props = defineProps({
  numOfProjects: {
    type: Number,
    default: 0,
  },
  numOfProviders: {
    type: Number,
    default: 0,
  },
  numOfMeta: {
    type: Number,
    default: 0,
  },
  numOfAccounts: {
    type: Number,
    default: 0,
  },
  numOfCampaigns: {
    type: Number,
    default: 0,
  },
});
const numOfProjects = ref(props.numOfProjects);
const numOfProviders = ref(props.numOfProviders);
const numOfMeta = ref(props.numOfMeta);
const numOfAccounts = ref(props.numOfAccounts);
const numOfCampaigns = ref(props.numOfCampaigns);

const noProjectsMessage = ref("Não há projetos criados");
const noProvidersMessage = ref("Não há providers");
const noMetaMessage = ref("Não há contas meta");
const noAccountsMessage = ref("Não há contas");
const noCampaignsMessage = ref("Não há campanhas");

const goToProjects = () => {
  router.push({ name: "Projects" });
};
const goToProviders = () => {
  router.push({ name: "Providers" });
};
const goToMeta = () => {
  router.push({ name: "MetaAccounts" });
};
const hideCreateNewProjectButton = computed(() => {
  return !numOfProjects.value || numOfProjects.value < 1;
});
watch(
  [
    () => props.numOfProjects,
    () => props.numOfProviders,
    () => props.numOfMeta,
    () => props.numOfAccounts,
    () => props.numOfCampaigns,
  ],
  ([
    newProjectsNum,
    newProvidersNum,
    newMetaNum,
    newAccountNum,
    newCampaignNum,
  ]) => {
    numOfProjects.value = newProjectsNum;
    numOfProviders.value = newProvidersNum;
    numOfMeta.value = newMetaNum;
    numOfAccounts.value = newAccountNum;
    numOfCampaigns.value = newCampaignNum;

    if (numOfProjects.value === 0) {
      noProjectsMessage.value = "Não há projetos criados";
    }
    if (numOfProviders.value === 0) {
      noProvidersMessage.value = "Não há providers";
    }
    if (numOfMeta.value === 0) {
      noMetaMessage.value = "Não há contas meta";
    }
    if (numOfAccounts.value === 0) {
      noAccountsMessage.value = "Não há contas";
    }
    if (numOfCampaigns.value === 0) {
      noCampaignsMessage.value = "Não há campanhas";
    }
  }
);
const getNumberClass = (num) => {
  return num > 0 ? "align-self-start" : "align-self-center";
};
</script>
<template>
  <section id="count-stats" class="pt-4 pb-6">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div
          class="col-md-2 cursor-pointer"
          :class="getNumberClass(numOfProjects)"
        >
          <div v-if="numOfProjects > 0" @click="goToProjects">
            <DefaultCounterCard
              title="Projetos"
              description="Projetos criados no Ampliva Wishper"
              :count="numOfProjects"
              :duration="3000"
              color="success"
              divider="vertical"
            />
          </div>
          <div v-else class="text-warning">
            <p>{{ noProjectsMessage }}</p>
          </div>
        </div>
        <div
          class="col-md-2 cursor-pointer"
          :class="getNumberClass(numOfProviders)"
          @click="goToProviders"
        >
          <div v-if="numOfProviders > 0">
            <DefaultCounterCard
              title="Provedores de Soluções"
              description="Seus parceiros de soluções da Meta API conectados ao Ampliva Wishper"
              :count="numOfProviders"
              :duration="3000"
              color="success"
              divider="vertical"
            />
          </div>
          <div v-else class="text-warning">
            <p>{{ noProvidersMessage }}</p>
          </div>
        </div>
        <div
          class="col-md-2 cursor-pointer"
          :class="getNumberClass(numOfMeta)"
          @click="goToMeta"
        >
          <div v-if="numOfMeta > 0">
            <DefaultCounterCard
              title="Conta Meta"
              description="Contas da Meta conectadas ao Ampliva Wishper"
              :count="numOfMeta"
              :duration="4000"
              color="success"
              divider="vertical"
            />
          </div>
          <div v-else class="text-warning">
            <p>{{ noMetaMessage }}</p>
          </div>
        </div>
        <div
          class="col-md-2 cursor-pointer"
          :class="getNumberClass(numOfAccounts)"
          @click="goToMeta"
        >
          <div v-if="numOfAccounts > 0">
            <DefaultCounterCard
              title="Contas de Anúncios"
              description="Contas de anúncios conectadas ao Ampliva Wishper"
              :count="numOfAccounts"
              :duration="4000"
              color="success"
              divider="vertical"
            />
          </div>
          <div v-else class="text-warning">
            <p>{{ noAccountsMessage }}</p>
          </div>
        </div>
        <div
          class="col-md-2 cursor-pointer"
          :class="getNumberClass(numOfCampaigns)"
          @click="goToMeta"
        >
          <div v-if="numOfCampaigns > 0">
            <DefaultCounterCard
              title="Campanhas"
              description="Campanhas conectadas no Ampliva Wishper"
              :count="numOfCampaigns"
              :duration="4000"
              color="success"
              divider="vertical"
            />
          </div>
          <div v-else class="text-warning">
            <p>{{ noCampaignsMessage }}</p>
          </div>
        </div>
      </div>
      <NewProject v-show="hideCreateNewProjectButton" />
    </div>
  </section>
</template>
