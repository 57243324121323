<script setup>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { useStore } from "vuex";
import { ref, defineExpose, defineEmits } from "vue";

const confirmationCode = ref("");
const newPassword = ref("");
const passwordConfirm = ref("");
const showToast = ref(false);
const toastMsg = ref("ok");
const toastColor = ref("primary");
const store = useStore();
const emit = defineEmits(["confirmation-submitted"]);
const bootstrap = store.state.bootstrap;
const props = defineProps({
  isForgotPassword: {
    type: Boolean,
    required: false,
  },
});
// eslint-disable-next-line no-unused-vars
const openModal = () => {
  var myModal = new bootstrap.Modal(
    document.getElementById("confirmationModal")
  );
  myModal.show();
};
// eslint-disable-next-line no-unused-vars
const closeModal = () => {
  var myModal = bootstrap.Modal.getInstance(
    document.getElementById("confirmationModal")
  );
  myModal.hide();
};
function validatePassword(password) {
  if (password.length < 6) {
    return "Password should be at least 6 characters long";
  }
  const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  if (!specialCharacterRegex.test(password)) {
    return "Password should contain at least one special character";
  }
  const numberRegex = /[0-9]/;
  if (!numberRegex.test(password)) {
    return "Password should contain at least one number";
  }
  return null;
}
const showInformationToast = (color, msg) => {
  toastColor.value = color;
  toastMsg.value = msg;
  showToast.value = true;
  setTimeout(() => {
    showToast.value = false;
  }, 5 * 1000);
};
const submit = () => {
  if (!confirmationCode.value && props.isForgotPassword) {
    showInformationToast("danger", "Verification code is required");
    return;
  }
  if (!newPassword.value || !passwordConfirm.value) {
    showInformationToast(
      "danger",
      "Password and confirm password are required"
    );
    return;
  }

  if (newPassword.value !== passwordConfirm.value) {
    showInformationToast(
      "danger",
      "Password and confirm password do not match"
    );
    return;
  }
  const passwordValidationError = validatePassword(newPassword.value);
  if (passwordValidationError) {
    showInformationToast("danger", passwordValidationError);
    return;
  }
  emit("confirmation-submitted", {
    confirmationCode: confirmationCode.value,
    newPassword: newPassword.value,
  });
  closeModal();
};

defineExpose({
  openModal,
  closeModal,
});
</script>
<template>
  <div
    id="confirmationModal"
    class="modal modal-custom fade"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="row col-12">
            <div v-show="showToast" class="col-12">
              <MaterialAlert
                id="alertId"
                ref="alertRef"
                v-focus
                class="cursor-pointer"
                :color="toastColor"
                fontWeight="bold"
                focusable="true"
                @click="showToast = false"
                >{{ toastMsg }}</MaterialAlert
              >
            </div>
            <h5 id="exampleModalLabel" class="modal-title col-10">
              Redefinir senha
            </h5>
            <MaterialButton
              variant="gradient"
              color="none"
              class="btn-close col-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
            </MaterialButton>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="isForgotPassword">
            <label for="verification-code-input"
              >Digite o código de verificação:</label
            >
            <MaterialInput
              id="verification-code-input"
              v-model="confirmationCode"
              type="text"
              class="form-control"
              placeholder="Código de verificação"
            />
          </div>

          <label for="password-input">Digite sua nova senha:</label>
          <MaterialInput
            id="password-input"
            v-model="newPassword"
            type="password"
            class="form-control"
            placeholder="Nova Senha"
          />

          <label for="password-confirm-input">Confirme sua nova senha:</label>
          <MaterialInput
            id="password-confirm-input"
            v-model="passwordConfirm"
            type="password"
            class="form-control"
            placeholder="Confirme a nova senha"
          />

          <p class="text-muted mb-2">
            Please follow this guide for a strong password:
          </p>
          <ul class="text-muted ps-4 mb-0 float-start">
            <li>
              <span class="text-sm">One special characters</span>
            </li>
            <li>
              <span class="text-sm">Min 6 characters</span>
            </li>
            <li>
              <span class="text-sm">One number (2 are recommended)</span>
            </li>
            <li>
              <span class="text-sm">Change it often</span>
            </li>
          </ul>
        </div>
        <div class="modal-footer justify-content-between">
          <MaterialButton
            variant="gradient"
            color="dark"
            data-bs-dismiss="modal"
          >
            Close
          </MaterialButton>
          <MaterialButton
            variant="gradient"
            color="success"
            class="mb-0"
            @click="submit"
          >
            Save changes
          </MaterialButton>
        </div>
      </div>
    </div>
  </div>
</template>
