<script setup>
import { ref, onMounted } from "vue";
//image
import bg0 from "@/assets/img/banner-whisper-meta-whatsapp.png";

//sections
import Numbers from "./Sections/Numbers.vue";
import { listAllProjects } from "@/services/projectsDataSource";
import { listAllProviders } from "@/services/providersDataSource";
import { getFacebookInfos } from "@/services/facebookDataSource";
import { listAccounts } from "@/services/adAccountsDataSource";
import { listCampaigns } from "@/services/campaignsDataSource";

const numOfProjects = ref(0);
const numOfProviders = ref(0);
const numOfMeta = ref(0);
const numOfAccounts = ref(0);
const numOfCampaigns = ref(0);
onMounted(async () => {
  const projects = await listAllProjects();
  const providers = await listAllProviders();
  const metaAccounts = await getFacebookInfos();
  const accounts = await listAccounts();
  const campaigns = await listCampaigns();
  numOfProjects.value = projects && projects.length;
  numOfProviders.value = providers && providers.length;
  numOfMeta.value = metaAccounts && metaAccounts.length;
  numOfAccounts.value = accounts && accounts.length;
  numOfCampaigns.value = campaigns && campaigns.length;
});
</script>
<template>
  <header class="py-7">
    <div class="pb-3 card card-body shadow-xl mx-3 mx-md-4 mt-n6">
      <div class="row pt-3 mt-3 justify-content-center">
        <div class="col-lg-12">
          <Numbers
            :num-of-projects="numOfProjects"
            :num-of-providers="numOfProviders"
            :num-of-meta="numOfMeta"
            :num-of-accounts="numOfAccounts"
            :num-of-campaigns="numOfCampaigns"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="mt-3 card card-body shadow-xl mx-3 mx-md-4 mt-n6">
    <div class="row pt-3 mt-3 justify-content-center">
      <div class="col-lg-12">
        <div
          class="page-header min-vh-35"
          :style="{ backgroundImage: `url(${bg0})` }"
        ></div>
      </div>
    </div>
  </div>
</template>
