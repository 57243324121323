import { generateClient } from "@aws-amplify/api";
import { listProviders } from "@/graphql/queries";
import { listProvidersWithInfo } from "@/graphql/customQueries";
import {
  createProvider,
  updateProvider,
  deleteProvider,
} from "@/graphql/mutations";
import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";
import { updateProjectDetails } from "./projectsDataSource";

export const createNewProvider = async (providerDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    const check = checkInputs(userId, providerDetails);
    if (check === null) {
      return null;
    }
    providerDetails.userId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: createProvider,
      variables: { input: providerDetails },
    });
    const createdProvider = response.data.createProvider;
    await updateProject(
      providerDetails.projectId,
      createdProvider.id
    );
    return createdProvider;
  } catch (error) {
    console.error("Error creating provider: ", error);
    throw error;
  }
};
export const updateProviderDetails = async (providerDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    const check = checkInputs(userId, providerDetails);
    if (check === null) {
      return null;
    }
    providerDetails.userId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: updateProvider,
      variables: { input: providerDetails },
    });
    const updatedProvider = response.data;
    return updatedProvider;
  } catch (error) {
    console.error("Error updating provider: ", error);
    throw error;
  }
};
export const updateProviderDetailsWithProjectDisconnection = async (providerDetails) => {
  const resp = await updateProviderDetails(providerDetails);
  if(!providerDetails.providerStatus){
    await updateProject(providerDetails.projectId, null);
  }
  return resp;
  }
const checkInputs = (userId, providerDetails) => {
  if (!userId) {
    console.error("Error getting userId: ", userId);
    return null;
  }
  if (!providerDetails.projectId || providerDetails.projectId === "") {
    console.error(
      "Error getting projectId ou projectId: ",
      providerDetails.projectId + " - " + providerDetails.projectId
    );
    return null;
  }
  return true;
};
const updateProject = async (projectId, providerId) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    const projectDetails = {
      id: projectId,
      providerId,
      userId,
    };
    const updatedProject = await updateProjectDetails(projectDetails);
    return updatedProject;
  } catch (error) {
    console.error("Error updating project: ", error);
    throw error;
  }
};
export const deleteProviderById = async (projectId, providerId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: deleteProvider,
      variables: { input: { id: providerId } },
    });
    const deletedProvider = response.data;
    try {
      updateProject(projectId, null);
    } catch (error) {
      console.error("Error update project after deleting provider: ", error);
    }
    return deletedProvider;
  } catch (error) {
    console.error("Error deleting provider: ", error);
    throw error;
  }
};
export const listAllProviders = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listProviders,
      variables: { limit: 1000 },
    });
    const providers = response.data.listProviders.items;
    return providers;
  } catch (error) {
    console.error("Error listing providers: ", error);
    throw error;
  }
};
export const listAllProvidersWithInfo = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listProvidersWithInfo,
      variables: { limit: 1000 },
    });
    const providers = response.data.listProviders.items;
    return providers;
  } catch (error) {
    console.error("Error listing providers: ", error);
    throw error;
  }
}