import { generateClient } from "@aws-amplify/api";
import { listProjects, getProject } from "@/graphql/queries";
import { listProjectsWithInfo } from "@/graphql/customQueries";
import {
  createProject,
  updateProject,
  deleteProject,
} from "@/graphql/mutations";
import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";

export const createNewProject = async (projectDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value ? dynamoDBUser.value.id : null;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    projectDetails.userId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: createProject,
      variables: { input: projectDetails },
    });
    const createdProject = response.data.createProject;
    return createdProject;
  } catch (error) {
    console.error("Error creating project: ", error);
    throw error;
  }
};
export const updateProjectDetails = async (projectDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    projectDetails.userId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: updateProject,
      variables: { input: projectDetails },
    });
    const updatedProject = response.data;
    return updatedProject;
  } catch (error) {
    console.error("Error updating project: ", error);
    throw error;
  }
};
export const deleteProjectById = async (projectId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: deleteProject,
      variables: { input: { id: projectId } },
    });
    const deletedProject = response.data;
    return deletedProject;
  } catch (error) {
    console.error("Error deleting project: ", error);
    throw error;
  }
};
export const listAllProjects = async () => {
  try {
    const userStore = useAuthStore();
    const { userProjects } = storeToRefs(userStore);
    const client = generateClient();
    const response = await client.graphql({
      query: listProjects,
      variables: { limit: 1000 },
    });
    const projects = response.data.listProjects.items;
    userProjects.value = projects;
    return projects;
  } catch (error) {
    console.error("Error listing all projects: ", error);
    throw error;
  }
};
export const listAllProjectsWithInfo = async () => {
  try {
    const userStore = useAuthStore();
    const { userProjects } = storeToRefs(userStore);
    const client = generateClient();
    const response = await client.graphql({
      query: listProjectsWithInfo,
      variables: { limit: 1000 },
    });
    const projects = response.data.listProjects.items;
    userProjects.value = projects;
    return projects;
  } catch (error) {
    console.error("Error listing all projects: ", error);
    throw error;
  }
}
export const getProjectById = async (projectId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: getProject,
      variables: { id: projectId },
    });
    const project = response.data.getProject;
    return project;
  } catch (error) {
    console.error("Error getting project: ", error);
    throw error;
  }
};
