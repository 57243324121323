<script setup>
import { onMounted, onUnmounted } from "vue";

//image
import bg0 from "@/assets/img/banner-whisper-meta-whatsapp.png";

//dep
import Typed from "typed.js";

//sections
import ProvidersTable from "./ProvidersTable.vue";

const body = document.getElementsByTagName("body")[0];

const description = "Provedores de serviços de negócios conectados"
//hooks
onMounted(() => {
  body.classList.add("about-us");
  body.classList.add("bg-gray-200");

  if (document.getElementById("typed")) {
    // eslint-disable-next-line no-unused-vars
    var typed = new Typed("#typed", {
      stringsElement: "#typed-strings",
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });
  }
});
onUnmounted(() => {
  body.classList.remove("about-us");
  body.classList.remove("bg-gray-200");
});
</script>
<template>
  <header class="bg-gradient-dark border-radius-2xl mx-3">
    <div class="page-header mt-3 min-vh-25 border-radius-2xl" :style="{ backgroundImage: `url(${bg0})` }">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container pt-5">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center mx-auto my-auto">
            <p class="lead mb-4 text-white opacity-8">
              {{ description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="row pt-3 mt-3 justify-content-center">
    <div class="col-lg-12">
      <ProvidersTable />
    </div>
  </div>
</template>
