<script setup>
import { ref, onMounted } from 'vue';
import ProvidersTableComponent from "./components/ProvidersTableComponent.vue";
import { listAllProvidersWithInfo } from "@/services/providersDataSource";

const rows = ref([]);
const formatDate = (date) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
  return new Date(date).toLocaleDateString('pt-BR', options);
}

const teste = ref(0);

onMounted(async () => {

  const providers = await listAllProvidersWithInfo();
  rows.value = providers.map(provider => ({
    projectName: provider.project ? provider.project.projectName : provider.projectId,
    solutionId: provider.solutionId,
    status: !!provider.solutionId && !!provider.projectId && !!provider && provider.providerStatus,
    date: formatDate(provider.createdAt),
    conections: true,
    action: {
      label: "Ver",
      route: provider.projectId,
    },
  }))
  teste.value = providers.length

});

</script>
<template>
  <section>
    <div class="container">
      <div class="row align-items-center">
        <ProvidersTableComponent :rows= "rows"></ProvidersTableComponent>
      </div>
    </div>
  </section>
</template>
