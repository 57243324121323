<script setup>
import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, watch } from "vue";
const props = defineProps({
  headers: {
    type: Array,
    default: () => ["Conta", "Nome", "Status", "Conectado em", "Conectada"],
  },
  rows: {
    type: Array,
    required: true,
    dbId: String,
    createdAt: String,
    facebookAccountId: String,
    connectionStatus: Boolean,
    checked: Boolean,
    name: String,
    objective: String,
    primary_attribution: String,
    created_time: String,
    start_time: String,
    status: String,
    stop_time: String,
    updated_time: String,
    source_campaign: {
      id: String,
    },
    effective_status: String,
    buying_type: String,
    account_id: String,
    id: String,
  },
});
const rowsRef = ref(props.rows);
const buttonText = ref("Selecionar todos");
const buttonColor = ref("light");
const emit = defineEmits(["update-selected-rows"]);
watch([() => props.rows], (newRows) => {
  rowsRef.value = newRows[0];
});
const toggleSelectAllCampaigns = () => {
  rowsRef.value = rowsRef.value.map((row) => {
    row.checked = !row.checked;
    return row;
  });
  buttonText.value =
    buttonText.value === "Selecionar todos"
      ? "Desmarcar todos"
      : "Selecionar todos";
  buttonColor.value = buttonColor.value === "light" ? "secondary" : "light";
};
const selectCampaign = (campaign) => {
  if (!campaign.id) {
    return;
  }
  const selectedRows = rowsRef.value.map((row) => {
    if (row.id === campaign.id) {
      row.checked = campaign.checked;
    }
    return row;
  });
  rowsRef.value = selectedRows;
  emit("update-selected-rows", rowsRef.value);
};
</script>
<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="header"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              :class="{ 'ps-2': index == 1, 'text-center': index > 1 }"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              { id, account_id, name, connectionStatus, createdAt, checked }, index
            ) of rowsRef"
            :key="'cp' + index"
          >
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <!-- <p class="text-xs text-secondary mb-0"> -->
                  <!-- {{ id }} -->
                  <!-- </p> -->
                  <!-- <img :src="id" class="avatar avatar-sm me-3" /> -->
                </div>
              </div>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-xs">{{ account_id }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{ name }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <MaterialBadge
                variant="gradient"
                :color="connectionStatus ? 'success' : 'danger'"
              >
                {{ connectionStatus ? "Online" : "Offline" }}
              </MaterialBadge>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{
                createdAt
              }}</span>
            </td>
            <td class="align-middle text-center">
              <MaterialCheckbox
                :id="id"
                :checked="checked"
                @update="selectCampaign"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-end px-3">
        <MaterialButton
          class="center my-4 mb-2"
          variant="gradient"
          :color="buttonColor"
          @click="toggleSelectAllCampaigns"
          >{{ buttonText }}</MaterialButton
        >
      </div>
    </div>
  </div>
</template>
