import { listBSPS } from '@/graphql/queries';
import { generateClient } from "@aws-amplify/api";
import { createBSP } from '@/graphql/mutations';

export const getBSPSFromDB = async () => {
  const client = generateClient();
  try {
    const bspList = await client.graphql({
      query: listBSPS,
      // authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    return bspList.data.listBSPS.items;
  } catch (error) {
    console.error('Error listing BSPS: ', error);
  }
};

export const createNewBSP = async (bspDetails = {}) => {
  const client = generateClient();
  const response = await client.graphql({
    query: createBSP,
    variables: { input: bspDetails },
  });
  return response.data.createBSP;
}