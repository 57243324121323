<script setup>
//Vue Material Kit 2 components
import MaterialBadge from "@/components/MaterialBadge.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, watch } from "vue";

const store = useStore();
const props = defineProps({
  headers: {
    type: Array,
    default: () => [
      "Id da conta",
      "Conta Facebook",
      "Contas de anúncio conectadas",
      "Campanhas conectadas",
      "Status",
      "Criado em",
      "Conexão",
    ],
  },
  rows: {
    type: Array,
    required: true,
    projectName: String,
    fbUserName: String,
    status: Boolean,
    accounts: Number,
    campaigns: Number,
    date: String,
    action: {
      type: Object,
      label: String,
      route: String,
      default: () => {
        return {
          label: "Ver conexão",
          route: "#",
        };
      },
    },
  },
});

const router = useRouter();
const rowsRef = ref(props.rows);

watch([() => props.rows], async (newRows) => {
  rowsRef.value = newRows[0];
});

const gotoRoute = (route) => {
  try {
    router.push({ name: "EditProject", params: { id: route, step: "meta" } });
  } catch (error) {
    store.dispatch("setAlertTextAndColor", {
      text: error,
      color: "danger",
    });
  }
};
</script>
<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="header"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              :class="{ 'ps-2': index == 1, 'text-center': index > 1 }"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              {
                projectName,
                fbUserName,
                status,
                accounts,
                campaigns,
                date,
                action: { label, route },
              },
              index
            ) of rowsRef"
            :key="index"
          >
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center"></div>
              </div>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-xs">{{ projectName }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-xs font-weight-bold mb-0">{{ fbUserName }}</p>
            </td>
            <td class="text-center">
              <p class="text-xs font-weight-bold mb-0">{{ accounts }}</p>
              <!-- <p class="text-xs text-secondary mb-0">{{ campaigns }}</p> -->
            </td>
            <td class="text-center">
              <p class="text-xs font-weight-bold mb-0">{{ campaigns }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <MaterialBadge
                variant="gradient"
                :color="status ? 'success' : 'danger'"
              >
                {{ status ? "Online" : "Offline" }}
              </MaterialBadge>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{
                date
              }}</span>
            </td>
            <td class="align-middle text-center">
              <a
                class="btn btn-sm mt-3 text-secondary text-warning font-weight-bold text-xs"
                data-toggle="tooltip"
                data-original-title="Edit user"
                @click="gotoRoute(route)"
              >
                {{ label }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
