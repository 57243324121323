import { createRouter, createWebHashHistory } from "vue-router";
// import Default from "../views/dashboards/Default.vue";
// import Sales from "../views/dashboards/Sales.vue";
// import ProfileOverview from "../views/pages/profile/Overview.vue";
// import ProfileProjects from "../views/pages/profile/Projects.vue";
// import Timeline from "../views/pages/projects/Timeline.vue";
// import Pricing from "../views/pages/Pricing.vue";
// import RTL from "../views/pages/Rtl.vue";
// import Charts from "../views/pages/Charts.vue";
// import Notifications from "../views/pages/Notifications.vue";
// import Kanban from "../views/applications/Kanban.vue";
// import Wizard from "../views/applications/wizard/Wizard.vue";
// import DataTables from "../views/applications/DataTables.vue";
// import Calendar from "../views/applications/Calendar.vue";
// import NewProduct from "../views/ecommerce/products/NewProduct.vue";
// import EditProduct from "../views/ecommerce/products/EditProduct.vue";
// import ProductPage from "../views/ecommerce/products/ProductPage.vue";
// import OrderDetails from "../views/ecommerce/orders/OrderDetails";
// import OrderList from "../views/ecommerce/orders/OrderList";
// import NewUser from "../views/pages/users/NewUser.vue";
// import AccountSettings from "../views/pages/account/Settings.vue";
// import Billing from "../views/pages/account/Billing.vue";
// import Invoice from "../views/pages/account/Invoice.vue";
// import Widgets from "../views/pages/Widgets.vue";
// import Basic from "../views/auth/signin/Basic.vue";
// import Cover from "../views/auth/signin/Cover.vue";
// import Illustration from "../views/auth/signin/Illustration.vue";
// import ResetCover from "../views/auth/reset/Cover.vue";
// import SignupCover from "../views/auth/signup/Cover.vue";
import Presentation from "@/views/Home/Presentation.vue";
import Login from "@/views/Login/Illustration.vue";
import Overview from "@/views/Overview/Overview.vue";
import ProjectForm from "@/views/ProjectForm/Wizard.vue";
import Projects from "@/views/Projects/Projects.vue";
import Providers from "@/views/Providers/Providers.vue";
import MetaAccounts from "@/views/MetaAccounts/MetaAccounts.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Settings from "@/views/Settings/Settings.vue";
import { useAuthStore } from "@/store/auth/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Presentation,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },    
  {
    path: "/overview",
    name: "Overview",
    component: Overview,
    meta: {
      transition: "slide-right",
      requiresAuth: true,
    },
  },    
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      transition: "slide-right",
      requiresAuth: true,
    },
  },  
  // {
  //   path: "/newProject/:projectName",
  //   name: "CreateProject",
  //   component: ProjectForm,
  //   meta: {
  //     transition: "slide-right",
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/newProject",
    name: "CreateProject",
    component: ProjectForm,
    meta: {
      transition: "slide-right",
      requiresAuth: true,
    },
  },
  {
    path: "/project/:id/:step",
    name: "EditProject",
    component: ProjectForm,
    meta: {
      transition: "slide-right",
      requiresAuth: true,
    },
  },  
  {
    path: "/dashboard/:id",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      transition: "slide-right",
      requiresAuth: true,
    },
  },
  {
    path: "/providers",
    name: "Providers",
    component: Providers,
    meta: {
      requiresAuth: true,
    },
  },  
  {
    path: "/metaAccounts",
    name: "MetaAccounts",
    component: MetaAccounts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/",
  //   name: "/",
  //   redirect: "/dashboards/dashboard-default",
  // },
  // {
  //   path: "/dashboards/dashboard-default",
  //   name: "Default",
  //   component: Default,
  // },
  // {
  //   path: "/dashboards/sales",
  //   name: "Sales",
  //   component: Sales,
  // },
  // {
  //   path: "/pages/profile/overview",
  //   name: "Profile Overview",
  //   component: ProfileOverview,
  // },
  // {
  //   path: "/pages/profile/projects",
  //   name: "All Projects",
  //   component: ProfileProjects,
  // },
  // {
  //   path: "/pages/projects/timeline",
  //   name: "Timeline",
  //   component: Timeline,
  // },
  // {
  //   path: "/pages/pricing-page",
  //   name: "Pricing Page",
  //   component: Pricing,
  // },
  // {
  //   path: "/pages/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  // {
  //   path: "/pages/charts",
  //   name: "Charts",
  //   component: Charts,
  // },
  // {
  //   path: "/pages/widgets",
  //   name: "Widgets",
  //   component: Widgets,
  // },
  // {
  //   path: "/pages/notifications",
  //   name: "Notifications",
  //   component: Notifications,
  // },
  // {
  //   path: "/applications/kanban",
  //   name: "Kanban",
  //   component: Kanban,
  // },
  // {
  //   path: "/applications/wizard",
  //   name: "Wizard",
  //   component: Wizard,
  // },
  // {
  //   path: "/applications/data-tables",
  //   name: "Data Tables",
  //   component: DataTables,
  // },
  // {
  //   path: "/applications/calendar",
  //   name: "Calendar",
  //   component: Calendar,
  // },
  // {
  //   path: "/ecommerce/products/new-product",
  //   name: "New Product",
  //   component: NewProduct,
  // },
  // {
  //   path: "/ecommerce/products/edit-product",
  //   name: "Edit Product",
  //   component: EditProduct,
  // },
  // {
  //   path: "/ecommerce/products/product-page",
  //   name: "Product Page",
  //   component: ProductPage,
  // },
  // {
  //   path: "/ecommerce/Orders/order-details",
  //   name: "Order Details",
  //   component: OrderDetails,
  // },
  // {
  //   path: "/ecommerce/Orders/order-list",
  //   name: "Order List",
  //   component: OrderList,
  // },
  // {
  //   path: "/pages/users/new-user",
  //   name: "New User",
  //   component: NewUser,
  // },
  // {
  //   path: "/pages/account/settings",
  //   name: "AccountSettings",
  //   component: AccountSettings,
  // },
  // {
  //   path: "/pages/account/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/pages/account/invoice",
  //   name: "Invoice",
  //   component: Invoice,
  // },
  // {
  //   path: "/authentication/signin/basic",
  //   name: "Signin Basic",
  //   component: Basic,
  // },
  // {
  //   path: "/authentication/signin/cover",
  //   name: "Signin Cover",
  //   component: Cover,
  // },
  // {
  //   path: "/authentication/signin/illustration",
  //   name: "Signin Illustration",
  //   component: Illustration,
  // },
  // {
  //   path: "/authentication/reset/cover",
  //   name: "Reset Cover",
  //   component: ResetCover,
  // },
  // {
  //   path: "/authentication/signup/cover",
  //   name: "Signup Cover",
  //   component: SignupCover,
  // },    
  // Rota de 404 - deve ser a última rota
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if (!authStore.isInitiated) {
    // console.log("Initializing auth store before routing...");
    await authStore.initAuthStore();
  }
  // console.log("Requires auth", to.meta.requiresAuth);
  if (to.meta.requiresAuth) {
    // console.log("Auth store initiated", authStore.isInitiated);
    // console.log("isAuthenticated", authStore.isAuthenticated);
    if (!authStore.isAuthenticated) {
      // console.log("Not authenticated");
      next({ name: "Login" });
    } else {
      // console.log("to: ", to);
      next();
    }
  } else {
    next();
  }
});

export default router;
