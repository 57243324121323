<script setup>
import { ref, onMounted, computed } from "vue";
import About from "./Sections/About.vue";
import ChatBot from "./Sections/ChatBot.vue";
import MetaAdAccount from "./Sections/MetaAdAccount.vue";

import { getProjectById } from "@/services/projectsDataSource";
import { getBSPSFromDB } from "@/services/bspDataSource";
import { useRouter } from "vue-router";

const projectNameInput = ref("");
const companyNameInput = ref("");
const bspInput = ref("");
const userEnteredBSPInput = ref("");
const enableProjectInputs = ref(true);
const isOtherOptionSelected = ref(false);
const bsps = ref([]);
const providerInfo = ref("");
const facebookInfoId = ref("");
const fbInfo = ref("");
const activeClass = ref("js-active position-relative");
const activeStep = ref(0);
const formSteps = ref(2);
const projectId = ref("");
const isEditPage = computed(() => projectId.value);
const currentPathObject = ref(null);

onMounted(() => {
  initProjectByURLParams();
  initProject();
  const body = document.getElementsByTagName("body")[0];
  body.classList.add("about-us");
  body.classList.add("bg-gray-200");
});
const initProjectByURLParams = () => {
  const router = useRouter();
  currentPathObject.value = router.currentRoute.value;
  const currentPath = currentPathObject.value;
  if (currentPath) {
    const params = currentPath.params;
    if (params) {
      if (params.id) {
        projectId.value = params.id;
      }
      if (params.projectName) {
        const projectNameFromOverview =
          currentPathObject.value.params.projectName;
        projectNameInput.value = projectNameFromOverview;
      }
    }
  }
  if (currentPathObject.value.params.step) {
    switch (currentPathObject.value.params.step) {
      case "project":
        activeStep.value = 0;
        break;
      case "chatbot":
        activeStep.value = 1;
        break;
      case "meta":
        activeStep.value = 2;
        break;
      default:
        activeStep.value = 0;
        break;
    }
  }
};
const setActiveStepTo1 = () => {
  activeStep.value = isEditPage.value ? 1 : 0;
};
const setActiveStepTo2 = () => {
  activeStep.value = isEditPage.value ? 2 : 0;
};
const nextStep = () => {
  if (activeStep.value < formSteps.value) {
    activeStep.value += 1;
  } else {
    activeStep.value -= 1;
  }
};
const prevStep = () => {
  if (activeStep.value > 0) {
    activeStep.value -= 1;
  }
};
const updateProjectId = (id) => {
  projectId.value = id;
  initProject();
};
const initProject = async () => {
  await getBSPlist();
  if (projectId.value) {
    const projectData = await getProjectById(projectId.value);
    if (projectData && projectData.id) {
      const bspAlreadyConnected = bsps.value.filter(
        (bsp) => bsp.code == projectData.bspCode
      )[0];
      if (!bspAlreadyConnected) {
        isOtherOptionSelected.value = true;
      } else {
        isOtherOptionSelected.value = false;
      }

      projectNameInput.value =
        projectData.projectName || projectNameInput.value;
      companyNameInput.value =
        projectData.companyName || companyNameInput.value;
      bspInput.value = getBSPCode(bspAlreadyConnected) || "Outro";
      userEnteredBSPInput.value = projectData.bspCode || "y";
      facebookInfoId.value = projectData.fbInfoId;
      providerInfo.value = projectData.provider;
      fbInfo.value = projectData.fbInfo;
    }
  } else {
    console.info("No project id");
  }
  enableProjectInputs.value = !projectId.value;
};
const getBSPlist = async () => {
  try {
    const bspList = await getBSPSFromDB();
    bsps.value = bspList;
  } catch (error) {
    console.error("Error getting BSPS: ", error);
  }
};
const getBSPCode = (bspAlreadyConnected) => {
  return isOtherOptionSelected.value ? "Outro" : bspAlreadyConnected;
};
</script>
<template>
  <div>
    <div class="py-4 h-100 container-fluid">
      <div class="row">
        <div class="text-center col-12">
          <h3 class="mt-5">Configure seu projeto</h3>
          <h5 class="text-secondary font-weight-normal">
            Esse processo nos possibilitará saber como configurar seu projeto.
          </h5>
          <div class="mb-5 multisteps-form">
            <!--progress bar-->
            <div class="row">
              <div class="mx-auto my-5 col-12 col-lg-10">
                <div class="card">
                  <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                  >
                    <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                    >
                      <div class="multisteps-form__progress">
                        <button
                          class="multisteps-form__progress-btn js-active"
                          type="button"
                          title="Project Basic Info"
                          :class="activeStep >= 0 ? activeClass : ''"
                          @click="activeStep = 0"
                        >
                          <span>Projeto</span>
                        </button>
                        <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Chatbot"
                          :class="activeStep >= 1 ? activeClass : ''"
                          @click="setActiveStepTo1"
                        >
                          <span>BSP</span>
                        </button>
                        <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Meta Ad Cmapaigns"
                          :class="activeStep >= 2 ? activeClass : ''"
                          @click="setActiveStepTo2"
                        >
                          <span>Meta & Campanhas</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <form class="multisteps-form__form">
                      <About 
                        v-if="activeStep === 0" 
                        :propProjectId="projectId"  
                        :projectName="projectNameInput"
                        :companyName="companyNameInput"
                        :bspCode="bspInput"
                        :bsps="bsps"
                        @next-step="nextStep"
                        @update-project-id="updateProjectId" />
                      <ChatBot
                        id="solutionIdSection"
                        ref="solutionIdSectionRef"
                        :projectId="projectId"
                        :providerInfo="providerInfo"
                        :class="activeStep === 1 ? activeClass : ''"
                        image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                        title="Conexão com o Chatbot"
                        description="Solicitamos que insira o Solution ID correspondente relacionados ao projeto e a empresa acima. Isso permitirá que a Ampliva se conecte com o seu BSP e crie o acompanhamento do funil completo.
            Após o Solution ID ser aprovado, o campo de conexção da Waba ficará disponivel para conexão!"
                        :refId="facebookSectionRef"
                        @next-step="nextStep"
                        @prev-step="prevStep"
                        @update-data="initProject"
                      />
                      <MetaAdAccount
                        id="facebookSectionRef"
                        ref="facebookSectionRef"
                        :projectId="projectId"
                        :fbInfo="fbInfo"
                        :class="activeStep === 2 ? activeClass : ''"
                        title="Facebook Meta"
                        description="Solicitamos que faça login com sua conta da Meta e escolha as contas de anúncios e campanhas que deseja conectar com a Ampliva."
                        @update-data="initProject"
                        @prev-step="prevStep"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
