<script setup>
import { ref, computed, onMounted, watchEffect } from "vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

const selectedAccount = ref("");
const selectedCampaign = ref("");
const selectedAdset = ref("");
const selectedAd = ref("");
const selectedDateStart = ref("");
const selectedDateStop = ref("");
const useCustomFilters = ref(false);
const selfData = ref([]);

onMounted(() => {
  selfData.value = props.data.value;
});
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

watchEffect(() => {
  useCustomFilters.value = !useCustomFilters.value;
});
const filteredData = computed(() => {
  return useCustomFilters.value && useCustomFilters.value.length
    ? selfData.value.filter((item) => {
        return (
          item &&
          (selectedAccount.value === "Todas" ||
            item.account_name === selectedAccount.value) &&
          (selectedCampaign.value === "Todas" ||
            item.campaign_name === selectedCampaign.value) &&
          (selectedAdset.value === "Todas" ||
            item.adset_name === selectedAdset.value) &&
          (selectedAd.value === "Todas" || item.ad_name === selectedAd.value) &&
          (selectedDateStart.value === "Todas" ||
            item.date_start >= selectedDateStart.value) &&
          (selectedDateStop.value === "Todas" ||
            item.date_stop <= selectedDateStop.value)
        );
      })
    : props.data;
});

const countImpressions = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.impressions),
    0
  )
);
const countSpend = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.spend),
    0
  )
);
const countClicks = computed(() =>
  filteredData.value.reduce(
    (total, item) => Number(total) + Number(item.link_click || 0),
    0
  )
);
const countCPC = computed(() => countSpend.value / (countClicks.value || 1));
const countCTR = computed(
  () => (countClicks.value / (countImpressions.value || 1)) * 100
);
</script>

<template>
  <div class="row">
    <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <mini-statistics-card
            :title="{ text: 'Investimento', value: countSpend, prefix: 'R$ ', decimals: 2}"
            detail="<span class='text-success text-sm font-weight-bolder'></span> Investimentos nas campanhas no período"
            :icon="{
              name: 'attach_money',
              color: 'text-white',
              background: 'primary',
            }"
          />
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <mini-statistics-card
            :title="{ text: 'Impressões', value: countImpressions }"
            detail="<span class='text-success text-sm font-weight-bolder'></span> Impressões geradas com o investimento"
            :icon="{
              name: 'devices',
              color: 'text-white',
              background: 'primary',
            }"
          />
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <mini-statistics-card
            :title="{ text: 'Cliques', value: countClicks }"
            detail="<span class='text-success text-sm font-weight-bolder'></span> Cliques nas impressões"
            :icon="{
              name: 'ads_click',
              color: 'text-white',
              background: 'tertiary',
            }"
          />
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <mini-statistics-card
            :title="{ text: 'CPC', value: countCPC, prefix: 'R$ ', decimals: 2}"
            detail="<span class='text-success text-sm font-weight-bolder'></span> Custo pelos cliques"
            :icon="{
              name: 'attach_money',
              color: 'text-white',
              background: 'primary',
            }"
          />
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <mini-statistics-card
            :title="{ text: 'CTR', value: countCTR, suffix: ' %', decimals: 2}"
            detail="<span class='text-success text-sm font-weight-bolder'></span> Cliques por impressões"
            :icon="{
              name: 'touch_app',
              color: 'text-white',
              background: 'primary',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
