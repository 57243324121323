export const listProjectsWithInfo = /* GraphQL */ `
  query ListProjectsWithInfo(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        projectName
        companyName
        bspCode
        bspId
        bsp {
          name
        }
        providerId
        provider {
          providerStatus
          solutionId
        }
        fbInfoId
        fbInfo {
          facebookStatus
          fbUserName
          facebookAccounts {
            items{
              accountStatus
              accountId
              name
              facebookCampaigns {
                items {
                  campaignStatus
                  campaignId
                  name
                  effectiveStatus
                  buyingType
                }
              }
            }
            __typename
          }
        }
        userId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listProvidersWithInfo = /* GraphQL */ `
  query ListProvidersWithInfo(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        providerStatus
        solutionId
        wabaId
        userId
        projectId
        owner
        project {
          createdAt
          updatedAt
          projectName
          companyName
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listFacebookFullInfos = /* GraphQL */ `
  query ListFacebookFullInfos(
    $filter: ModelFacebookInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacebookInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        facebookStatus
        accessToken
        fbUserId
        fbUserName
        userId
        projectId
        owner
        facebookAccounts {
          items {
            id
            createdAt
            updatedAt
            accountStatus
            accountId
            name
            facebookInfoId
            owner
            facebookCampaigns {
              items {
                id
                createdAt
                updatedAt
                campaignStatus
                campaignId
                name
                effectiveStatus
                buyingType
                facebookAccountId
                owner
                __typename
              }
              __typename
            }
            __typename
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;