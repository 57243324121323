<script setup>
import SideNavItem from "./components/SideNavItem.vue";
import { onMounted, defineComponent } from "vue";

const body = document.getElementsByTagName("body")[0];
onMounted(() => {
  body.classList.add("about-us");
  body.classList.add("bg-gray-200");
});
defineComponent({
  name: "Settings",
  components: {
    SideNavItem,
  },
});
</script>
<template>
  <div class="container-fluid mt-4">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>
