<script setup>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { useAuthStore } from "@/store/auth/auth";
import setMaterialInput from "@/assets/js/material-input";
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import EmailModal from "./EmailModal";
import ConfirmationModal from "./ConfirmationModal";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import { useStore } from "vuex";

const { signIn, finishLoginProcessAfterRedefinePassword } = useAuthStore();

const emailInput = ref("");
const password = ref("");
const emailModalRef = ref(null);
const confirmationModalRef = ref(null);
const isForgotPassword = ref(false);

const store = useStore();
const body = document.getElementsByTagName("body")[0];

onMounted(() => {
  setMaterialInput();
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
  body.classList.remove("bg-gray-100");
});
onUnmounted(() => {
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
  body.classList.add("bg-gray-100");
});
const openEmailModal = () => {
  if (emailModalRef.value) {
    emailModalRef.value.openModal();
  } else {
    isForgotPassword.value = false;
    console.error("Email modal reference is null");
  }
};
const openConfirmationModal = () => {
  if (confirmationModalRef.value) {
    confirmationModalRef.value.openModal();
  } else {
    console.error("Confirmation modal reference is null");
  }
};
async function handleSignIn(event) {
  event.preventDefault();
  event.preventDefault();
  try {
    emailInput.value = emailInput.value.toLowerCase();
    const signInResp = await signIn(emailInput.value, password.value);
    if (
      signInResp &&
      signInResp === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    ) {
      openConfirmationModal();
    } else {
      console.info("signInResp", signInResp);
    }
  } catch (error) {
    console.error("Error during signIn", error);
    store.dispatch("setAlertTextAndColor", {
      color: "danger",
      text: error.message,
    });
  }
}
async function handleResetPassword(event) {
  event.preventDefault();
  openEmailModal();
}
const handleEmailModal = async (email) => {
  if (!email) return;
  emailInput.value = email.toLowerCase();
  try {
    isForgotPassword.value = true;
    const signInStep = await resetPassword({ username: email });
    handleResetPasswordNextSteps(signInStep);
  } catch (error) {
    isForgotPassword.value = false;
    console.error(error);
    store.dispatch("setAlertTextAndColor", {
      color: "warning",
      text: "Error with email submitted: " + error.message,
    });
  }
};
async function handleResetPasswordNextSteps(signInStep) {
  var stepCode = signInStep;
  var nextStep = stepCode.nextStep;
  if (nextStep) {
    if (nextStep.resetPasswordStep) {
      stepCode = nextStep.resetPasswordStep;
    } else if (nextStep.signInStep) {
      stepCode = nextStep.signInStep;
    } else {
      console.log("nextStep", nextStep);
    }
  }
  if (stepCode === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
    console.warn("oldFormatCode: ", stepCode);
    openConfirmationModal();
  } else if (stepCode === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
    openConfirmationModal();
  } else {
    isForgotPassword.value = false;
    store.dispatch("setAlertTextAndColor", {
      color: "danger",
      text: "Error on reset password",
    });
  }
}
const handleConfirmationModal = ({ confirmationCode, newPassword }) => {
  // console.log("confirmationCode", confirmationCode);
  if (isForgotPassword.value) {
    handleResetPasswordConfirmationSubmitted({
      confirmationCode: confirmationCode,
      newPassword: newPassword,
    });
  } else {
    finishLoginProcessAfterRedefinePassword(newPassword);
  }
};
const handleResetPasswordConfirmationSubmitted = async ({
  confirmationCode,
  newPassword,
}) => {
  if (!emailInput.value || !confirmationCode || !newPassword) return;
  try {
    const resp = await confirmResetPassword({
      username: emailInput.value,
      confirmationCode: confirmationCode,
      newPassword: newPassword,
    });
    store.dispatch("setAlertTextAndColor", {
      color: "success",
      text: "Password changed successfully! " + resp,
    });
  } catch (error) {
    isForgotPassword.value = false;
    console.error("handleResetPasswordConfirmationSubmitted: ", error);
    store.dispatch("setAlertTextAndColor", {
      color: "danger",
      text: "Error on reset password: " + error,
    });
  }
};
</script>
<template>
  <div class="bg-white">
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            is-blur="blur my-3 py-2 mt-4 start-0 end-0 mx-4 shadow blur border-radius-lg"
            btn-background="bg-gradient-primary"
            btn-text="VOLTAR"
            :dark-mode="true"
          />
        </div>
      </div>
    </div>
    <main class="mt-0 main-content">
      <EmailModal
        ref="emailModalRef"
        :emailInput="emailInput"
        @email-submitted="handleEmailModal"
      />
      <ConfirmationModal
        ref="confirmationModalRef"
        :isForgotPassword="isForgotPassword"
        @confirmation-submitted="handleConfirmationModal"
      />
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column"
              >
                <div
                  class="page-header position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/illustrations/whisper_login.png') +
                      ')',
                  }"
                ></div>
              </div>
              <div
                class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5"
              >
                <div class="card card-plain">
                  <div class="pb-0 card-header text-center bg-transparent mb-4">
                    <h4 class="font-weight-bolder">Bem-vindo</h4>
                    <p class="mb-0">Digite seu e-mail e senha para entrar</p>
                  </div>
                  <div class="card-body">
                    <form role="form">
                      <div class="mb-3">
                        <material-input
                          id="email"
                          v-model="emailInput"
                          type="email"
                          label="Email"
                          name="email"
                          size="lg"
                        />
                      </div>
                      <div class="mb-3">
                        <material-input
                          id="password"
                          v-model="password"
                          type="password"
                          label="Password"
                          name="password"
                          size="lg"
                        />
                      </div>
                      <div class="text-center">
                        <material-button
                          class="mt-4"
                          variant="gradient"
                          color="primary"
                          full-width
                          size="lg"
                          @click="handleSignIn"
                          >Sign in</material-button
                        >
                      </div>
                    </form>
                    <div class="text-center pt-5">
                      <p class="mx-auto text-sm">
                        Esqueceu sua senha?

                        <MaterialButton
                          class="pt-3 btn-link text-warning text-gradient font-weight-bold"
                          full-width
                          @click="handleResetPassword"
                          >Recuperar senha</MaterialButton
                        >
                      </p>
                    </div>
                  </div>
                  <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-4 text-sm">
                      Don't have an account?
                      <router-link
                        :to="{ name: 'Signup Cover' }"
                        class="text-success text-gradient font-weight-bold"
                        >Sign up</router-link
                      >
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
