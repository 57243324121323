<script setup>
import {
  ref,
  // nextTick
} from "vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { useAuthStore } from "@/store/auth/auth";

const currentPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const alertRef = ref(null);
const showToast = ref(false);
const toastMsg = ref("ok");
const toastColor = ref("primary");

const authStore = useAuthStore();
const { handleUpdateUserPassword } = authStore;
function validatePassword(password) {
  if (password.length < 6) {
    return "Password should be at least 6 characters long";
  }
  const specialCharacterRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  if (!specialCharacterRegex.test(password)) {
    return "Password should contain at least one special character";
  }
  const numberRegex = /[0-9]/;
  if (!numberRegex.test(password)) {
    return "Password should contain at least one number";
  }
  return null;
}

const handleUpdatePassword = async () => {
  if (!currentPassword.value) {
    showInformationToast("danger", "Current password is required");
    return;
  }
  if (currentPassword.value === newPassword.value) {
    showInformationToast(
      "danger",
      "New password and current password are the same"
    );
    return;
  }
  if (
    !newPassword.value ||
    !confirmPassword.value ||
    newPassword.value !== confirmPassword.value
  ) {
    showInformationToast(
      "danger",
      "New password and confirm password do not match"
    );
    return;
  }
  const passwordValidationError = validatePassword(newPassword.value);
  if (passwordValidationError) {
    showInformationToast("danger", passwordValidationError);
    return;
  }
  const resp = await handleUpdateUserPassword(
    currentPassword.value,
    newPassword.value
  );
  if (resp) {
    showInformationToast("success", "Password updated successfully!");
  } else {
    showInformationToast("danger", "Error updating password");
  }
};
const showInformationToast = (color, msg) => {
  toastColor.value = color;
  toastMsg.value = msg;
  showToast.value = true;
  setTimeout(() => {
    showToast.value = false;
  }, 5 * 1000);
};
</script>
<template>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div v-show="showToast">
      <MaterialAlert
        id="alertId"
        ref="alertRef"
        v-focus
        class="cursor-pointer"
        :color="toastColor"
        fontWeight="bold"
        focusable="true"
        @click="showToast = false"
        >{{ toastMsg }}</MaterialAlert
      >
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <MaterialInput
          id="currentPassword"
          ref="alertRef"
          v-model="currentPassword"
          type="password"
          label="Current Password"
        />
      </div>
      <div class="mb-3">
        <MaterialInput
          id="newPassword"
          v-model="newPassword"
          type="password"
          label="New Password"
        />
      </div>
      <div class="mb-3">
        <MaterialInput
          id="confirmPassword"
          v-model="confirmPassword"
          type="password"
          label="Confirm password"
        />
      </div>
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <MaterialButton
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="handleUpdatePassword"
        >Update password</MaterialButton
      >
    </div>
  </div>
</template>
