<script setup>
import { ref, computed, onMounted, watch } from "vue";
import {
  Chart,
  LinearScale,
  BarController,
  LineController,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  TimeScale,
  Title,
} from "chart.js";
import "chartjs-adapter-date-fns";

import ApexCharts from "apexcharts";

Chart.register(
  LinearScale,
  BarController,
  LineController,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  TimeScale,
  Title
);

// let chart;
let chartA;

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});
const dataRef = ref(props.data);
let chartRef = ref(null);

const sortedFilteredData = computed(() => {
  return dataRef.value.slice().sort((a, b) => {
    const dateA = new Date(a.date_stop.replace(/-/g, "/"));
    const dateB = new Date(b.date_stop.replace(/-/g, "/"));
    return dateA - dateB;
  });
});
const investmentValuesPerDay = computed(() => {
  const groupedByDate = sortedFilteredData.value.reduce((acc, item) => {
    const date = item.date_stop;
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date] += Number(item.spend);
    return acc;
  }, {});
  return Object.values(groupedByDate);
});
// const maxInvestmentValuePerDay = computed(() => {
//   return Math.max(...investmentValuesPerDay.value);
// });
const leadCountsPerDay = computed(() => {
  const groupedByDate = sortedFilteredData.value.reduce((acc, item) => {
    const date = item.date_stop;
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date] += Number(item.etapa_1) || 0;
    return acc;
  }, {});
  return Object.values(groupedByDate);
});
// const maxLeadCountPerDay = computed(() => {
//   return Math.max(...leadCountsPerDay.value);
// });
const uniqueDates = computed(() => {
  return sortedFilteredData.value
    .map((item) => item.date_stop)
    .filter((date_stop, index, self) => {
      return self.indexOf(date_stop) === index;
    });
});

// const createChart = () => {
//   const ctx = document.getElementById("myChart").getContext("2d");
//   let gradient = ctx.createLinearGradient(0, 0, 0, 600);
//   gradient.addColorStop(0, "rgba(41, 139, 236, 0.9)");
//   gradient.addColorStop(1, "rgba(55, 39, 132, 0)");
//   if (chart) {
//     console.log("destroying chart");
//     chart.destroy();
//   }

//   let maxInvestmentValue = maxInvestmentValuePerDay.value * 1.05;
//   let maxLeadCount = maxLeadCountPerDay.value * 1.05;
//   chart = new Chart(ctx, {
//     type: "line",
//     data: {
//       labels: uniqueDates.value,
//       datasets: [
//         {
//           label: "Investimento",
//           data: 100,
//           borderColor: "rgba(25, 39, 5, 1)",
//           yAxisID: "y1",
//         },
//         {
//           type: "bar",
//           label: "Leads",
//           data: 222,
//           backgroundColor: gradient,
//           borderColor: "rgba(41, 139, 236, 1)",
//           yAxisID: "y2",
//         },
//       ],
//     },
//     options: {
//       title: {
//         display: true,
//         text: "Valor investido x leads gerados", // Descrição do gráfico
//       },
//       scales: {
//         x: {
//           type: "time",
//           time: {
//             unit: "day",
//           },
//         },
//         y1: {
//           type: "linear",
//           position: "left",
//           min: 0,
//           max: maxInvestmentValue,
//         },
//         y2: {
//           type: "linear",
//           position: "right",
//           min: 0,
//           max: maxLeadCount,
//         },
//       },
//       legend: {
//         display: true,
//         labels: {
//           fontColor: "rgb(255, 99, 132)",
//         },
//       },
//       tooltips: {
//         enabled: true,
//         mode: "index",
//         intersect: false,
//         callbacks: {
//           label: function (tooltipItem, data) {
//             var label = data.datasets[tooltipItem.datasetIndex].label || "";
//             if (label) {
//               label += ": ";
//             }
//             label += Math.round(tooltipItem.yLabel * 100) / 100;
//             return label;
//           },
//         },
//       },
//     },
//   });
//   chart.update();
// };
const createChartA = () => {
  if (!chartRef.value) {
    return;
  }
  if (chartA) {
    chartA.destroy();
  }
  let options = {
    chart: {
      height: 450,
      type: "line",
    },
    series: [
      {
        name: "Investimento",
        type: "line",
        data: investmentValuesPerDay.value,
      },
      {
        name: "Leads",
        type: "bar",
        data: leadCountsPerDay.value,
      },
    ],
    xaxis: {
      categories: uniqueDates.value,
    },
    yaxis: [
      {
        title: {
          text: "Investimento",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Leads",
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
    ],
  };

  chartA = new ApexCharts(chartRef.value, options);

  chartA.render();
};
watch([() => props.data], (newValues) => {
  const [newData] = newValues;
  dataRef.value = newData;
  // createChart();
  createChartA();
});
onMounted(() => {
  // createChart();
  createChartA();
});
</script>

<template>
  <section id="count-stats" class="pt-3 pb-4">
    <div class="container card">
      <div class="row">
        <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
          <div class="row">
            <h4 class="text-center">Valor investido x leads gerados</h4>
            <div ref="chartRef"></div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-12 z-index-2 border-radius-xl mx-auto py-3">
          <div class="row">
            <h4 class="text-center">Valor investido x leads gerados</h4>
            <canvas id="myChart"></canvas>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>
