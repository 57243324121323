<script setup>
import { watchEffect, onMounted, onUnmounted } from "vue";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

const props = defineProps({
  minDateStart: {
    type: String,
    default: new Date().toISOString().split("T")[0],
  },
  maxDateStop: {
    type: String,
    default: new Date().toISOString().split("T")[0],
  },
  title: {
    type: [Object, String],
    default: null,
    text: {
      type: String,
    },
    color: {
      type: String,
    },
  },
});
let fpInstance = null;
onMounted(() => {
  fpInstance = flatpickr("#datePicker", {
    locale: Portuguese,
    mode: "range",
    dateFormat: "Y-m-d",
    minDate: props.minDateStart,
    maxDate: props.maxDateStop,
    defaultDate: [props.minDateStart, props.maxDateStop],
    onChange: function (selectedDates) {
      if (selectedDates.length >= 2) {
        const selectedDateStart = selectedDates[0].toISOString().split("T")[0];
        const selectedDateStop = selectedDates[1].toISOString().split("T")[0];
        selectRange([selectedDateStart, selectedDateStop]);
      }
    },
  });
});

watchEffect(() => {
  if (fpInstance) {
    fpInstance.destroy();
  }
  fpInstance = flatpickr("#datePicker", {
    locale: Portuguese,
    mode: "range",
    dateFormat: "Y-m-d",
    minDate: props.minDateStart,
    maxDate: props.maxDateStop,
    defaultDate: [props.minDateStart, props.maxDateStop],
    onChange: function (selectedDates) {
      if (selectedDates.length >= 2) {
        const selectedDateStart = selectedDates[0].toISOString().split("T")[0];
        const selectedDateStop = selectedDates[1].toISOString().split("T")[0];
        selectRange([selectedDateStart, selectedDateStop]);
      }
    },
  });
});
onUnmounted(() => {
  if (fpInstance) {
    fpInstance.destroy();
  }
});
const emits = defineEmits(["update:selectedRange"]);
const selectRange = (range) => {
  emits("update:selectedRange", range);
};
</script>
<template>
  <div class="mb-4 card">
    <div class="p-3 card-body">
      <div
        class="row"
        :class="directionReverse ? reverseDirection : 'justify-content-between'"
      >
        <div class="col-md-12">
          <div class="mb-3">
            <label for="projectSelect" class="form-label">{{
              title.text
            }}</label>

            <div class="col-md-12">
              <input
                id="datePicker"
                placeholder="Selecione as datas.."
                data-input
              />
            </div>
          </div>
        </div>
        <div
          v-if="subtitle"
          class="text-end mt-2"
          :class="classContent ? classContent : ''"
        >
          <div class="numbers">
            <p
              class="mb-0 text-sm text-capitalize"
              :class="subtitle ? subtitle.color : ''"
            >
              {{
                typeof subtitle === "string"
                  ? subtitle
                  : subtitle
                  ? subtitle.text
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
