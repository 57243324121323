import { generateClient } from "@aws-amplify/api";
import { createUser, updateUser, deleteUser } from "@/graphql/mutations";
import { listUsers, getUser } from "@/graphql/queries";
import { useAuthStore } from "@/store/auth/auth";
import { storeToRefs } from "pinia";

export const createNewUser = async (userDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { user, dynamoDBUser } = storeToRefs(userStore);
    const userId = user.value.userId;
    if (!userId) {
      console.error("Error getting userId: ", userId);
      return null;
    }
    userDetails.cognitoId = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: createUser,
      variables: { input: userDetails },
    });
    const createdUser = response.data.createUser;
    dynamoDBUser.value = createdUser;
    return createdUser;
  } catch (error) {
    console.error("Error creating user: ", error);
    throw error;
  }
};
export const updateUserDetails = async (userDetails = {}) => {
  try {
    const userStore = useAuthStore();
    const { dynamoDBUser } = storeToRefs(userStore);
    const userId = dynamoDBUser.value.id;
    userDetails.id = userId;
    const client = generateClient();
    const response = await client.graphql({
      query: updateUser,
      variables: { input: userDetails },
    });
    const updatedUser = response.data.updateUser;
    dynamoDBUser.value = updatedUser;
    return updatedUser;
  } catch (error) {
    console.error("Error updating user: ", error);
    throw error;
  }
};
export const deleteUserById = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: deleteUser,
      variables: { input: { id: userId } },
    });
    return response.data.deleteUser;
  } catch (error) {
    console.error("Error deleting user: ", error);
    throw error;
  }
};
export const getAllUsers = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({ query: listUsers });
    return response.data.listUsers.items;
  } catch (error) {
    console.error("Error getting all users: ", error);
    throw error;
  }
};
export const getUserById = async (userId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: getUser,
      variables: { filter: { cognitoId: { eq: userId } } },
    });
    return response.data.getUser;
  } catch (error) {
    console.error("Error getting user by id: ", error);
    throw error;
  }
};
export const initUser = async (cognitoUserId, email) => {
  try {
    var user = null;
    if (cognitoUserId) {
      user = await listUserByCognitoId(cognitoUserId);
      if (!user || user.items.length <= 0) {
        user = createNewUser({
          name: "New user " + email,
          email
        });
      } else {
        const firstCreatedDynamoDBUser = user.items[0];
        user = firstCreatedDynamoDBUser;
      }
    } else {
      console.error("Error getting cognitoUserId: ", cognitoUserId);
    }
    return user;
  } catch (err) {
    console.error("err: ", err);
    throw err;
  }
};
const listUserByCognitoId = async (cognitoDBId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listUsers,
      variables: { filter: { cognitoId: { eq: cognitoDBId } } },
    });
    return response.data.listUsers;
  } catch (error) {
    console.error("Error getting user by cognitoId: ", error);
    return null;
  }
};
