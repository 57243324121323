<!--
=========================================================
* Vue Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 RealSolv (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <sidenav
    v-if="showSidenav"
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <div v-show="showAlert">
      <MaterialAlert
        id="alertId"
        ref="alertRef"
        v-focus
        class="mt-1 cursor-pointer"
        :color="alertColor"
        fontWeight="bold"
        focusable="true"
        @click="toggleAlert"
      >
        {{ alertText }}
      </MaterialAlert>
    </div>
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
// import { useAuthStore } from "@/store/auth/auth";

const store = useStore();

const state = computed(() => store.state);
const isRTL = computed(() => state.value.isRTL);
const color = computed(() => state.value.color);
const isAbsolute = computed(() => state.value.isAbsolute);
const isNavFixed = computed(() => state.value.isNavFixed);
const navbarFixed = computed(() => state.value.navbarFixed);
const absolute = computed(() => state.value.absolute);
const showSidenav = computed(() => state.value.showSidenav);
const showNavbar = computed(() => state.value.showNavbar);
const showFooter = computed(() => state.value.showFooter);
const showConfig = computed(() => state.value.showConfig);
const hideConfigButton = computed(() => state.value.hideConfigButton);
const showAlert = computed(() => state.value.showAlert);
const alertColor = computed(() => state.value.alertColor);
const alertText = computed(() => state.value.alertText);

const toggleConfigurator = () => store.commit("toggleConfigurator");
const navbarMinimize = () => store.commit("navbarMinimize");
const toggleAlert = () => store.commit("toggleAlert");

onBeforeMount(async () => {
  const sidenav = document.getElementsByClassName("g-sidenav-show")[0];
  if (window.innerWidth > 1200) {
    sidenav.classList.add("g-sidenav-pinned");
  }
  // const authStore = useAuthStore();
  // if (!authStore.isInitiated) {
  //   console.log("initAuthStore app.vue");
    // const resp = await authStore.initAuthStore();
    // console.log("initAuthStore app.vue resp", resp);
  // }
});
</script>

<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #66bb6a 0%,
    #43a047 100%
  ) !important;
}
</style>
