<script setup>
import { onMounted, ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import BigNumbersCard from "./Sections/BigNumbersCard.vue";
import ChartCard from "./Sections/ChartCard.vue";
import FunnelCard from "./Sections/FunnelCard.vue";
import FunnelBigNumbersCard from "./Sections/FunnelBigNumbersCard.vue";
// import mockData from "@/data2.json";
import MiniFilterCard from "./Sections/components/MiniFilterCard.vue";
import MiniDateFilterCard from "./Sections/components/MiniDateFilterCard.vue";
// import VErrorBoundary from "vue-error-boundary";
import axios from "axios";
import setMaterialInput from "@/assets/js/material-input";
import { listAllProjectsWithInfo } from "@/services/projectsDataSource";

import { useAuthStore } from "@/store/auth/auth";

const allSelected = "Todas";

const userName = ref("");
const authStore = useAuthStore();
const { handleFetchUserAttributes } = authStore;

const attributes = computed(() => {
  return handleFetchUserAttributes();
});
attributes.value.then((resp) => {
  if (!resp) {
    return;
  }
  const name = resp.name;
  userName.value = name;
});

const router = useRouter();
const currentPathObject = ref(router.currentRoute);
const urlParams = currentPathObject.value
  ? currentPathObject.value.params
  : null;
const projectId = ref("");
if (urlParams) {
  projectId.value = urlParams.id;
}
const projectNameInput = ref("");

const enableProjectInputs = ref(true);
const projectsList = ref([]);
const selectedProject = ref(null);
const selectedAccount = ref("");
const selectedCampaign = ref("");
const selectedAd = ref("");
const selectedDateStart = ref("");
const selectedDateStop = ref("");
const showAllFilters = ref(false);
const etlData = ref([]);

const body = document.getElementsByTagName("body")[0];
onMounted(() => {
  body.classList.add("about-us");
  body.classList.add("bg-gray-200");
  const projectNameFromOverview = router.currentRoute.value.params.projectName;
  projectNameInput.value = projectNameFromOverview;

  // let flatData = flattenData(mockData);
  // etlData.value = flatData;
  initProjects();
  getETLData();
});
watchEffect(() => {
  if (selectedProject.value && selectedProject.value.id) {
    getProject(selectedProject.value.id);
  }
});
function flattenData(rawData) {
  let result = [];
  function recurse(data) {
    for (let i = 0; i < data.length; i++) {
      if (Array.isArray(data[i])) {
        recurse(data[i]);
      } else {
        result.push(data[i]);
      }
    }
  }

  recurse(rawData);

  return result;
}
// eslint-disable-next-line no-unused-vars
const getETLData = async () => {
  const baseURL =
    "https://ruytm6zdva.execute-api.us-east-1.amazonaws.com/whisper/dahboard";
  const params = {
    senha: process.env.VUE_APP_API_PSWD,
    project_id: selectedProject.value ? selectedProject.value.id : "00001",
    client_id: userName.value ? userName.value : "ailos",
  };
  try {
    const response = await axios.get(baseURL, {
      params,
    });
    const data = response.data ? JSON.parse(response.data.data) : [];
    let flatData = flattenData(data);

    etlData.value = flatData;
    // const dataStr =
    //   "data:text/json;charset=utf-8," +
    //   encodeURIComponent(JSON.stringify(data));
    // const downloadAnchorNode = document.createElement("a");
    // downloadAnchorNode.setAttribute("href", dataStr);
    // downloadAnchorNode.setAttribute("download", "data.json");
    // document.body.appendChild(downloadAnchorNode); // required for firefox
    // downloadAnchorNode.click();
    // downloadAnchorNode.remove();
  } catch (error) {
    console.error("getETLData error: ", error);
  }
  // Object.keys(params).forEach((key) =>
  //   url.searchParams.append(key, params[key])
  // );

  // const response = await fetch(url);
  // const data = await response.json();
};
const initProjects = async () => {
  const projects = await listAllProjectsWithInfo();
  projectsList.value = projects;
  selectProjectFromUrl();
};
function selectProjectFromUrl() {
  const setDefault = () => {
    selectedAccount.value = allSelected;
    selectedCampaign.value = allSelected;
    selectedAd.value = allSelected;
    selectedDateStart.value = allSelected;
    selectedDateStop.value = allSelected;
  };
  if (projectId.value && projectsList.value) {
    const project = projectsList.value.find((p) => p.id === projectId.value);
    if (project) {
      selectedProject.value = project;
      setDefault();
    } else {
      selectedProject.value = allSelected;
      setDefault();
    }
  }
}
function getProjectFromList(projectId) {
  return projectsList.value.find((project) => project.id === projectId);
}
const getProject = async (id) => {
  setMaterialInput();
  if (id) {
    projectId.value = id;
    showAllFilters.value = true;
  }
  if (projectId.value) {
    const projectData = getProjectFromList(projectId.value);
    if (projectData.id) {
      projectNameInput.value =
        projectData.projectName || projectNameInput.value;
    }
  } else {
    console.info("No project id");
  }
  enableProjectInputs.value = !projectId.value;
  getETLData();
};

const parseDate = (dateStr) => {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split("-");
  return new Date(year, month - 1, day);
};
const minDateStart = computed(() => {
  const x = etlData.value
    .map((item) => parseDate(item.date_start))
    .sort((a, b) => a - b)[0];
  return x;
});
const maxDateStop = computed(() => {
  const x = etlData.value
    .map((item) => parseDate(item.date_stop))
    .sort((a, b) => b - a)[0];
  return x;
});
const campaignNames = computed(() => [
  ...new Set(
    etlData.value
      .filter(
        (item) =>
          selectedAccount.value === allSelected ||
          item.account_name === selectedAccount.value
      )
      .map((item) => item.campaign_name)
  ),
]);
const adNames = computed(() => [
  ...new Set(
    etlData.value
      .filter(
        (item) =>
          (selectedAccount.value === allSelected ||
            item.account_name === selectedAccount.value) &&
          (selectedCampaign.value === allSelected ||
            item.campaign_name === selectedCampaign.value)
      )
      .map((item) => item.ad_name)
  ),
]);
// const dateStops = computed(() => {
//   const dates = [
//     ...new Set(
//       etlData.value
//         .filter(
//           (item) =>
//             (selectedAccount.value === allSelected ||
//               item.account_name === selectedAccount.value) &&
//             (selectedCampaign.value === allSelected ||
//               item.campaign_name === selectedCampaign.value) &&
//             (selectedAd.value === allSelected ||
//               item.ad_name === selectedAd.value) &&
//             (selectedDateStop.value === allSelected ||
//               item.date_start >= selectedDateStop.value)
//         )
//         .map((item) => item.date_stop)
//     ),
//   ];
//   return dates.sort((a, b) => a - b);
// });
// const dateStarts = computed(() => {
//   const dates = [
//     ...new Set(
//       etlData.value
//         .filter(
//           (item) =>
//             (selectedAccount.value === allSelected ||
//               item.account_name === selectedAccount.value) &&
//             (selectedCampaign.value === allSelected ||
//               item.campaign_name === selectedCampaign.value) &&
//             (selectedAd.value === allSelected || item.ad_name === selectedAd.value)
//         )
//         .map((item) => item.date_start)
//     ),
//   ];
//   return dates.sort((a, b) => a - b);
// });
function checkItem(item) {
  const isCampaignMatch =
    selectedCampaign.value === allSelected ||
    item.campaign_name === selectedCampaign.value;
  const isAdMatch =
    selectedAd.value === allSelected || item.ad_name === selectedAd.value;
  const isDateStartMatch =
    selectedDateStart.value === allSelected ||
    item.date_start >= selectedDateStart.value;
  const isDateStopMatch =
    selectedDateStop.value === allSelected ||
    item.date_stop <= selectedDateStop.value;
  return isCampaignMatch && isAdMatch && isDateStartMatch && isDateStopMatch;
}
const filteredData = computed(() => {
  const x = etlData.value.filter((item) => {
    return checkItem(item);
  });
  return x;
});
const setDates = (range) => {
  selectedDateStart.value = range[0];
  selectedDateStop.value = range[1];
};
const badgeCount = ref(1);
const resetBadge = () => {
  badgeCount.value = 0;
};
</script>
<template>
  <div>
    <div class="container pb-4">
      <div class="col-lg-12 mx-auto d-flex justify-content-center flex-column">
        <div class="row mt-4">
          <div class="col-lg-2 col-sm-6">
            <MiniFilterCard
              class="bg-white p-0"
              :title="{
                text: 'Projeto',
                color: 'opacity-9 text-gray',
              }"
              allItensText="Todos"
              :selectedItem="selectedProject"
              :itemList="projectsList"
              @update:selected-item="selectedProject = $event"
            />
          </div>
          <div class="col-lg-3 col-sm-6">
            <MiniFilterCard
              class="bg-white p-0"
              :title="{
                text: 'Campanha',
                color: 'opacity-9 text-gray',
              }"
              :selectedItem="selectedCampaign"
              :itemList="campaignNames"
              @update:selected-item="selectedCampaign = $event"
            />
          </div>
          <div class="col-lg-4 col-sm-6">
            <MiniFilterCard
              class="bg-white p-0"
              :title="{
                text: 'Anúncio',
                color: 'opacity-9 text-gray',
              }"
              allItensText="Todos"
              :selectedItem="selectedAd"
              :itemList="adNames"
              @update:selected-item="selectedAd = $event"
            />
          </div>
          <div class="col-lg-3 col-sm-6">
            <MiniDateFilterCard
              class="bg-white p-0"
              :title="{
                text: 'Data',
                color: 'opacity-9 text-gray',
              }"
              :minDateStart="minDateStart"
              :maxDateStop="maxDateStop"
              @update:selected-range="setDates"
            />
          </div>
          <!-- <div class="col-lg-2 col-sm-6">
            <MiniFilterCard
              class="bg-white p-0"
              :title="{
                text: 'Data fim',
                color: 'opacity-9 text-gray',
              }"
              :selectedItem="selectedDateStop"
              :itemList="dateStops"
              @update:selected-item="selectedDateStop = $event"
            />
          </div> -->
        </div>
        <div class="row">
          <h3 class="text-center mt-4 col-lg-10">Dashboard do projeto</h3>
          <button
            class="btn btn-primary position-relative col-lg-2"
            @click="resetBadge"
          >
            Enviar eventos
            <span
              v-show="badgeCount > 0"
              class="position-absolute top-10-px start-90 translate-middle badge rounded-pill bg-danger"
            >
              {{ badgeCount }}
              <span class="visually-hidden">unsend events</span>
            </span>
          </button>
        </div>
        <div
          v-if="projectId && filteredData"
          class="col-lg-12 pt-5 ms-auto mt-lg-0 mt-6"
        >
          <!-- <VErrorBoundary>
            <template #default> -->
          <BigNumbersCard :data="filteredData" />
          <FunnelBigNumbersCard :data="filteredData" />
          <!-- </template>
            <template #error="{ error, resetError }">
              <div>An error occurred: {{ error.message }}</div>
              <button @click="resetError">Tentar novamente</button>
            </template>
          </VErrorBoundary> -->
        </div>
        <div
          v-if="projectId && filteredData"
          class="col-lg-12 pt-5 ms-auto mt-lg-0 mt-6"
        >
          <!-- <VErrorBoundary>
            <template #default> -->
          <ChartCard :data="filteredData" />
          <!-- </template>
            <template #error="{ error, resetError }">
              <div>An error occurred: {{ error.message }}</div>
              <button @click="resetError">Try again</button>
            </template>
          </VErrorBoundary> -->
        </div>
        <div
          v-if="projectId && filteredData"
          class="col-lg-12 pt-5 ms-auto mt-lg-0 mt-6"
        >
          <!-- <VErrorBoundary>
            <template #default> -->
          <FunnelCard :data="filteredData" />
          <!-- </template>
            <template #error="{ error, resetError }">
              <div>An error occurred: {{ error.message }}</div>
              <button @click="resetError">Try again</button>
            </template>
          </VErrorBoundary> -->
        </div>
      </div>
    </div>
  </div>
</template>
./Sections/FunnelBigNumbersCard.vue
