import { generateClient } from "@aws-amplify/api";
import {
  createFacebookCampaign,
  updateFacebookCampaign,
  deleteFacebookCampaign,
} from "@/graphql/mutations";
import {
  listFacebookCampaigns,
  getFacebookCampaign,
  facebookCampaignsByFacebookAccountIdAndCreatedAt,
} from "@/graphql/queries";

export const createCampaign = async (campaignDetails = {}) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: createFacebookCampaign,
      variables: { input: campaignDetails },
    });
    return response.data.createFacebookCampaign;
  } catch (error) {
    console.error("Error createCampaign: ", error);
    return null;
  }
};

export const updateCampaign = async (campaignDetails = {}) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: updateFacebookCampaign,
      variables: { input: campaignDetails },
    });
    return response.data.updateFacebookCampaign;
  } catch (error) {
    console.error("Error updateCampaign: ", error);
    return null;
  }
};

export const deleteCampaign = async (campaignId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: deleteFacebookCampaign,
      variables: { input: { id: campaignId } },
    });
    return response.data.deleteFacebookCampaign;
  } catch (error) {
    console.error("Error deleteCampaign: ", error);
    return null;
  }
};

export const listCampaigns = async () => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listFacebookCampaigns,
    });
    return response.data.listFacebookCampaigns.items;
  } catch (error) {
    console.error("Error listCampaigns: ", error);
    return null;
  }
};

export const getCampaignById = async (campaignId) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: getFacebookCampaign,
      variables: { id: campaignId },
    });
    return response.data.getFacebookCampaign;
  } catch (error) {
    console.error("Error getCampaignById: ", error);
    return null;
  }
};

export const listCampaignsByFacebookAccountIdAndCreatedAt = async (
  facebookAccountId,
  createdAt
) => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: facebookCampaignsByFacebookAccountIdAndCreatedAt,
      variables: { facebookAccountId, createdAt },
    });
    return response.data.facebookCampaignsByFacebookAccountIdAndCreatedAt.items;
  } catch (error) {
    console.error("Error listCampaignsByFacebookAccountIdAndCreatedAt: ", error);
    return null;
  }
};
