<script setup>
import { ref, onMounted } from "vue";
import ProjectTable from "./ProjectTable.vue";
import { listAllProjectsWithInfo } from "@/services/projectsDataSource";

const rows = ref([]);

const formatDate = (date) => {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(date).toLocaleDateString("pt-BR", options);
};
const checkStatus = (project) => {
  const basicChecks =
    project.bspId &&
    project.provider &&
    project.provider.providerStatus &&
    project.provider.solutionId &&
    project.fbInfo &&
    project.fbInfo.facebookStatus;
  if (!basicChecks) return false;
  const facebookAccounts = project.fbInfo.facebookAccounts;
  const hasFacebookAccount =
    facebookAccounts && facebookAccounts.items.length > 0;
  const atLeastOneAccountIsActive =
    hasFacebookAccount &&
    facebookAccounts.items.find((acc) => acc.accountStatus).accountStatus;
  if (!atLeastOneAccountIsActive) return false;
  const facebookCampaigns = facebookAccounts.items.flatMap(
    (acc) => acc.facebookCampaigns
  );
  const flattenedItems = facebookCampaigns.flatMap(
    (campaign) => campaign.items
  );
  const hasFacebookCampaigns = flattenedItems && flattenedItems.length > 0;
  if (!hasFacebookCampaigns) return false;
  const hasActiveCampaign = flattenedItems.some(
    (acc) => acc.campaignStatus
  );
  return (
    basicChecks &&
    atLeastOneAccountIsActive &&
    hasFacebookCampaigns &&
    hasActiveCampaign
  );
};
onMounted(async () => {
  const projects = await listAllProjectsWithInfo();
  rows.value = projects.map((project) => ({
    id: project.id,
    projectName: project.projectName,
    companyName: project.companyName,
    connectionInfo: [
      project.bsp ? project.bsp.name : project.bspId,
      project.provider ? project.provider.solutionId : project.providerId,
      project.fbInfo ? project.fbInfo.fbUserName : project.fbInfoId,
    ],
    status: checkStatus(project),
    date: formatDate(project.createdAt),
    view: {
      viewLabel: "Dashboard",
      viewRoute: project.id,
    },
    action: {
      label: "Edit",
      route: project.id,
    },
  }));
});
</script>
<template>
  <section>
    <div class="container">
      <div class="row align-items-center">
        <ProjectTable :rows="rows"></ProjectTable>
      </div>
    </div>
  </section>
</template>
