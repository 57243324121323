import { ref, computed } from "vue";
import {
  // getCurrentUser as AuthCurrentAuthenticatedUser,
  signIn as AuthSignIn,
  signOut as AuthSignOut,
  signUp as AuthSignUp,
  fetchUserAttributes,
  updateUserAttribute,
  confirmUserAttribute,
  updatePassword,
  confirmSignIn,
} from "@aws-amplify/auth";
import { initUser } from "@/services/userDataSource";
import router from "@/router";
import { defineStore } from "pinia";
// Para obter a mesma resposta do "fetchUserAttributes" com mais informações como tokens de acesso
import { fetchAuthSession } from "@aws-amplify/core";
import { useStore } from "vuex";
// import { getCredentialsForIdentity } from "@aws-amplify/core";

export const useAuthStore = defineStore("auth", () => {
  const user = ref(null);
  const dynamoDBUser = ref(null);
  const userProjects = ref(null);
  const error = ref(null);
  const initiated = ref(false);
  const store = useStore();

  const isAuthenticated = computed(() => !!user.value);
  const isInitiated = computed(() => !!initiated.value);

  const initAuthStore = async () => {
    var currentUser = { userName: null, userId: null, email: null };
    try {
      initiated.value = true;

      const resp = await handleFetchUserAttributes();
      if (!resp) {
        // console.log("NOOOOO resp: ", resp);
        router.push({ name: "Home" });
        return;
      }
      // console.log("resp: ", resp);
      currentUser.userName = resp.name;
      currentUser.userId = resp.sub;
      currentUser.email = resp.email;

      user.value = currentUser;

      // router.push({ name: "Overview" });
      await initDynamoDBUser(resp.email);
      return true;
    } catch (err) {
      error.value = err;
      router.push({ name: "Login" });
      return false;
    }
  };
  const handleFetchUserAttributes = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      return userAttributes;
    } catch (error) {
      const errorMessage = error.message;
      console.error(errorMessage);
      return null;
    }
  };
  const initDynamoDBUser = async (email) => {
    if (!user.value || !email) {
      console.error("Error getting dynamoDB user: ", user.value);
      return;
    }
    const cognitoUserId = user.value.userId;
    try {
      const userDynamoDb = await initUser(cognitoUserId, email);
      dynamoDBUser.value = userDynamoDb;
    } catch (error) {
      console.error("Error getting dynamoDB user: ", error);
    }
  };
  // TODO: podemos tirar isso, é apenas uma outra forma de retornar dados do usuário, via fedarated login do fb, por exemplo
  // const fetchUserSession = async () => {
  //   try {
  //     var authSession = await fetchAuthSession();
  //     // const facebookAccessToken = credentials.credentials.webIdentityCredentials.params.Logins['accounts.facebook.com'];
  //     return authSession;
  //   } catch (error) {
  //     console.error("Error during facebookCheck", error);
  //     return null;
  //   }
  // };
  const signIn = async (username, password) => {
    try {
      const currentUser = await AuthSignIn({ username, password });
      var nextStep = currentUser.nextStep;
      var signInStep = nextStep.signInStep;
      console.log("signInStep: ", signInStep);
      if (signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        console.log("CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED", signInStep);
        return signInStep;
      } else {
        console.log("finishLoginProcess: ", signInStep);
        await finishLoginProcess();
      }
    } catch (err) {
      error.value = err;
      console.error("err: ", err);
      const errorMessage = err.message;
      if (errorMessage.includes("There is already a signed in user.")) {
        console.log("signOut");
        await signOut();
      }
      store.dispatch("setAlertTextAndColor", {
        text: errorMessage,
        color: "danger",
      });
    }
  };
  const finishLoginProcessAfterRedefinePassword = async (newPassword) => {
    // console.log(
    //   "finishLoginProcessAfterRedefinePassword newPassword: ",
    //   newPassword
    // );
    // const resp = 
    await confirmSignIn({
      challengeResponse: newPassword,
    });
    // console.log("finishLoginProcessAfterRedefinePassword resp: ", resp);
    await finishLoginProcess();
  };
  async function finishLoginProcess() {
    var authSession = await fetchAuthSession();
    const name = authSession.tokens.idToken.payload.name;
    const email = authSession.tokens.idToken.payload.email;
    const congnitoUser = {
      userId: authSession.userSub,
      userName: name,
      email: email,
    };
    user.value = congnitoUser;
    // console.log("congnitoUser: ", congnitoUser);
    await initDynamoDBUser(email);
    router.push({ name: "Overview" });
  }
  const signUp = async (userName, password, attributes) => {
    try {
      const currentUser = await AuthSignUp({ userName, password, attributes });
      user.value = currentUser;
      router.push({ name: "Overview" });
    } catch (err) {
      error.value = err;
      console.error("signUp err: ", err);
    }
  };
  const signOut = async () => {
    try {
      await AuthSignOut();
      resetStates();
      router.push({ name: "Login" });
    } catch (err) {
      error.value = err;
      console.error("signOut err: ", err);
    }
  };
  function resetStates() {
    user.value = null;
    dynamoDBUser.value = null;
    error.value = null;
    initiated.value = false;
  }
  async function handleUpdateUserAttribute(attributeKey, value) {
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey,
          value,
        },
      });
      await handleUpdateUserAttributeNextSteps(output);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async function handleUpdateUserAttributeNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.updateAttributeStep) {
      case "CONFIRM_ATTRIBUTE_WITH_CODE": {
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.info(
          `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
        );
        break;
      }
      case "DONE":
        initAuthStore();
        break;
      default:
        break;
    }
    return true;
  }
  async function handleConfirmUserAttribute(
    userAttributeKey,
    confirmationCode
  ) {
    try {
      const resp = await confirmUserAttribute({
        userAttributeKey,
        confirmationCode,
      });
      initAuthStore();
      return resp;
    } catch (error) {
      return null;
    }
  }
  async function handleUpdateUserPassword(oldPassword, newPassword) {
    if (!oldPassword || !newPassword) {
      return null;
    }
    if (oldPassword === newPassword) {
      return null;
    }
    try {
      const resp = await updatePassword({ oldPassword, newPassword });
      return resp || true;
    } catch (err) {
      console.error("Error during password update", err);
      return null;
    }
  }
  return {
    user,
    dynamoDBUser,
    userProjects,
    error,
    isInitiated,
    isAuthenticated,
    initAuthStore,
    signUp,
    signIn,
    finishLoginProcessAfterRedefinePassword,
    signOut,
    handleFetchUserAttributes,
    handleUpdateUserAttribute,
    handleConfirmUserAttribute,
    handleUpdateUserPassword,
  };
});
