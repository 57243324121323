<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { useAuthStore } from "@/store/auth/auth";
import MaterialButton from "@/components/MaterialButton.vue";
// import DefaultLinks from "./DefaultLinks.vue";

const userName = ref("");
const authStore = useAuthStore();
const { signOut } = useAuthStore();
const { handleFetchUserAttributes } = authStore;

const attributes = computed(() => {
  return handleFetchUserAttributes();
});
attributes.value.then((resp) => {
  if (!resp) {
    return;
  }
  const name = resp.name;
  userName.value = name;
});

const route = useRoute();
const getRoute = () => {
  const routeArr = route.path.split("/");
  return routeArr[1];
};

async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.error("Error during signOut", error);
  }
}
</script>
<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="dashboards-collapse"
          :nav-text="userName"
          :class="getRoute() === 'settings' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">account_circle</i>
            <!-- <img src="../../assets/img/team-3.jpg" class="avatar" /> -->
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="settings"
                text="Settings"
              />
            </ul>
            <ul class="nav pe-0 row">
              <div class="pe-5 d-block text-end">
                <div class="mt-3 mb-2">
                  <MaterialButton
                    class="text-nowrap mb-0"
                    variant="outline"
                    color="danger"
                    size="sm"
                    @click="handleSignOut"
                    >LogOut</MaterialButton
                  >
                </div>
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
    <hr class="horizontal light mt-2 mb-2" />
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'overview' ? 'active' : ''">
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'overview' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'overview' ? 'active' : ''"
                :to="{ name: 'Overview' }"
                mini-icon="view_comfy"
                text="Overview"
              />
            </div>
          </ul>
        </div>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboard' ? 'active' : ''">
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'dashboard' ? 'active' : ''"
                :to="{ name: 'Dashboard', params: { id: 'all' } }"
                mini-icon="analytics"
                text="Dashboards"
              />
            </div>
          </ul>
        </div>
      </li>
      <li class="nav-item" :class="getRoute() === 'projects' ? 'active' : ''">
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'projects' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'projects' ? 'active' : ''"
                :to="{ name: 'Projects' }"
                mini-icon="web_stories"
                text="Projetos"
              />
            </div>
          </ul>
        </div>
      </li>
      <li
        class="nav-item"
        :class="getRoute() === 'newProject' ? 'active' : ''"
      >
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'newProject' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'newProject' ? 'active' : ''"
                :to="{ name: 'CreateProject' }"
                mini-icon="library_add"
                text="Novo Projeto"
              />
            </div>
          </ul>
        </div>
      </li>
      <li class="nav-item" :class="getRoute() === 'providers' ? 'active' : ''">
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'providers' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'providers' ? 'active' : ''"
                :to="{ name: 'Providers' }"
                mini-icon="business_center"
                text="Provedores"
              />
            </div>
          </ul>
        </div>
      </li>
      <li
        class="nav-item"
        :class="getRoute() === 'metaAccounts' ? 'active' : ''"
      >
        <a
          v-show="false"
          class="nav-link"
          :class="getRoute() === 'metaAccounts' ? 'active' : ''"
        ></a>
        <div class="collapse show">
          <ul class="nav pe-0">
            <div class="nav-item active">
              <sidenav-item
                :class="getRoute() === 'metaAccounts' ? 'active' : ''"
                :to="{ name: 'MetaAccounts' }"
                mini-icon="verified"
                text="Contas Meta"
              />
            </div>
          </ul>
        </div>
      </li>
      <!-- <DefaultLinks /> -->
    </ul>
  </div>
</template>
