<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to || ''">
      <span class="sidenav-mini-icon" :class="textColor">
        <i v-if="miniIcon" class="material-icons-round opacity-10">{{ miniIcon }}</i>
      </span>
      <h6 class="sidenav-normal mt-2 mx-3 px-1" :class="textColor">
        {{ text }}
      </h6>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: false,
      default: () => {},
    },
    miniIcon: {
      type: String,
      required: false,
      default: () => {},
    },
    text: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: "text-white",
    },
  },
};
</script>
