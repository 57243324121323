<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

//Vue Material Kit 2 components
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

// material-input
import setMaterialInput from "@/assets/js/material-input";

// router
const router = useRouter();

const projectNameInputFromOverview = ref("");
const projectNamePlaceHolder = ref("Nome do projeto");

const handlerNewProject = () => {
  const projectNameForNewProject = projectNameInputFromOverview.value;
  router.push({ 
    name: "CreateProject", 
    params: { projectName: projectNameForNewProject } });
  }

onMounted(() => {
  setMaterialInput();
});

</script>
<template>
  <section class="mb-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 m-auto">
          <h4>Conecte seu primeiro projeto</h4>
          <p class="mb-4">
            Complete o cadastro para conectar seu primeiro projeto
          </p>
          <div class="row">
            <div class="col-8">
              <MaterialInput
                id="text"
                v-model="projectNameInputFromOverview"
                class="input-group-outline"
                :placeholder="projectNamePlaceHolder"
                type="text"
              />
            </div>
            <div class="col-4 ps-0">
              <MaterialButton
                variant="gradient"
                color="success"
                class="mb-0 h-100 position-relative z-index-2"
                @click="handlerNewProject">Iniciar</MaterialButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
