<script setup defer>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { ref, watch, computed } from "vue";
import {
  createNewProvider,
  updateProviderDetails,
  updateProviderDetailsWithProjectDisconnection,
} from "@/services/providersDataSource";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  projectId: {
    type: String,
    required: false,
    default: "",
  },
  providerInfo: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  solutionIdAction: {
    type: Object,
    color: String,
    route: String,
    label: String,
    default: () => ({
      color: "bg-gradient-success",
      label: "Conectar",
      route: "https://ampliva.com/pt-br/#/book-audit",
    }),
  },
  refId: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});
const emit = defineEmits(["next-step", "prev-step", "update-data"]);
const notifyParentToUpdateData = () => {
  emit("update-data");  
};

const providerId = ref(props.providerInfo ? props.providerInfo.id : null);
const solutionId = ref(
  props.providerInfo ? props.providerInfo.solutionId : null
);
const wabaCode = ref(props.providerInfo ? props.providerInfo.wabaCode : null);
const providerStatus = ref(
  props.providerInfo ? props.providerInfo.providerStatus : null
);
const showWabaSection = ref(false);
const fisrtInput = "Digite seu Solution ID";
const projectDetails = ref({});

const activateButtonText = computed(() =>
  providerStatus.value ? "Desativar" : "Ativar"
);
const hideSaveButton = computed(() => {
  return solutionId.value && wabaCode.value;
});
const providerStatusText = computed(() =>
  providerStatus.value ? "Conectado" : "Desconectado"
);
const textColor = computed(() =>
  providerStatus.value ? "text-success" : "text-danger"
);
const hideConnectButton = computed(() => {
  return !hideSaveButton.value;
});
watch([() => props.providerInfo], ([newProviderInfo]) => {
  providerId.value = newProviderInfo ? newProviderInfo.id : null;
  solutionId.value = newProviderInfo ? newProviderInfo.solutionId : null;
  wabaCode.value = newProviderInfo ? newProviderInfo.wabaId : null;
  providerStatus.value = newProviderInfo
    ? newProviderInfo.providerStatus
    : null;
  if (wabaCode.value) {
    showWabaSection.value = true;
  }
});
const submitSolutionId = async (event) => {
  event.preventDefault();
  if (!solutionId.value || solutionId.value === "") {
    const text = "Digite o Solution ID!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "danger",
    });
    return;
  }
  if (!validateSolutionId(solutionId.value)) {
    return;
  }
  await getWhatsappToken();
};
const validateSolutionId = (solutionId) => {
  const regex = /^\d{16}$/;
  if (!regex.test(solutionId)) {
    const text = "Solution ID deve ser uma string de 16 dígitos!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "danger",
    });

    return false;
  }
  return true;
};
const getWhatsappToken = async () => {
  /* global FB */
  await FB.login(
    function (response) {
      if (response.authResponse) {
        let accessToken = response.authResponse.code;
        wabaCode.value = accessToken;
        showWabaSection.value = true;
        providerStatus.value = true;
        if (providerId.value) {
          updateProvider();
        } else {
          createProvider();
        }
      } else {
        console.info("User cancelled login or did not fully authorize.");
      }
    },
    {
      config_id: process.env.VUE_APP_FACEBOOK_CONFIG_ID,
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {
          solutionID: solutionId.value,
        },
      },
    }
  );
  notifyParentToUpdateData();
};
const createProvider = async () => {
  projectDetails.value = {
    projectId: props.projectId,
    solutionId: solutionId.value,
    wabaId: wabaCode.value,
    providerStatus: true,
  };
  try {
    await createNewProvider(projectDetails.value);
    const text = "Conexão com BSP criada com sucesso!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "success",
    });
    notifyParentToUpdateData();
  } catch (error) {
    console.error("Error updating project: ", error);
  }
};
const updateProvider = async () => {
  projectDetails.value = {
    id: providerId.value,
    projectId: props.projectId,
    solutionId: solutionId.value,
    wabaId: wabaCode.value,
    providerStatus: providerStatus.value,
  };
  try {
    await updateProviderDetails(projectDetails.value);
    const text = "Conexão com BSP atualizada com sucesso!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "success",
    });
    notifyParentToUpdateData();
  } catch (error) {
    console.error("Error updating project: ", error);
  }
};
const handleProviderDesconection = async (event) => {
  event.preventDefault();
  providerStatus.value = false;
  await disconnectAccount();
};
const disconnectAccount = async () => {
  projectDetails.value = {
    id: providerId.value,
    projectId: props.projectId,
    solutionId: solutionId.value,
    wabaId: wabaCode.value,
    providerStatus: providerStatus.value,
  };
  try {
    const resp = await updateProviderDetailsWithProjectDisconnection(
      projectDetails.value
    );
    const text = "Conexão com BSP desconectada com sucesso!";
    store.dispatch("setAlertTextAndColor", {
      text,
      color: "success",
    });
    notifyParentToUpdateData();
    return resp;
  } catch (error) {
    console.error("Error disconnecting project: ", error);
    return error;
  }
};
const toggleAccountActivation = async (event) => {
  event.preventDefault();
  providerStatus.value = !providerStatus.value;
  updateProvider();
};
</script>
<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-normal">
          <a href="https://developers.facebook.com/apps" target="_blank">{{
            title
          }}</a>
        </h5>
        <p>
          Solicitamos que insira o Solution ID correspondente relacionados ao
          projeto e a empresa acima. Isso permitirá que a Ampliva se conecte com
          o seu BSP e crie o acompanhamento do funil completo. Após o Solution
          ID ser aprovado, o campo de conexção da Whastapp Business Account será conectado automaticamente!
        </p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="row">
        <div class="row col-12 pt-5">
          <div class="col-md-8">
            <MaterialInput
              id="solutionId"
              v-model="solutionId"
              variant="static"
              class="input-group-static mb-4"
              type="text"
              :label="fisrtInput"
            />
          </div>
          <div v-show="!hideSaveButton" class="col-md-4">
            <MaterialButton
              type="submit"
              variant="gradient"
              color="info"
              :class="solutionIdAction.color"
              full-width
              @click="submitSolutionId"
              >{{ solutionIdAction.label }}</MaterialButton
            >
          </div>
          <div v-show="hideSaveButton" class="col-md-4">
            <MaterialButton
              type="submit"
              variant="gradient"
              color="info"
              :class="solutionIdAction.color"
              full-width
              @click="handleProviderDesconection"
              >Desconectar</MaterialButton
            >
          </div>
        </div>
        <div v-if="showWabaSection" class="row col-12 pt-5">
          <div v-show="!hideConnectButton" class="col-md-8">
            <h4 class="font-weight-normal">
              Status da conexão com provedor:
              <span class="font-weight-bold" :class="textColor">{{
                providerStatusText
              }}</span>
            </h4>
          </div>
          <div v-show="!hideConnectButton" class="col-md-4">
            <MaterialButton
              type="submit"
              variant="gradient"
              color="warning"
              :class="solutionIdAction.color"
              full-width
              @click="toggleAccountActivation"
              >{{ activateButtonText }}</MaterialButton
            >
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <button
          class="mb-0 btn bg-gradient-light js-btn-prev"
          type="button"
          title="Prev"
          @click="emit('prev-step')"
        >
          Prev
        </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
          type="button"
          title="Next"
          @click="emit('next-step')"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
